import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';
import Hero from 'components/layout/Hero';
import FullImage from 'components/layout/FullImage';
import NextPage from 'components/layout/NextPage';
import CenterLine from 'components/layout/CenterLine';
import TitleContent from 'components/typography/TitleContent';
import DoubleParagraph from 'components/typography/DoubleParagraph';

const Architecture = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Page>
          <Hero image={require('src/assets/images/architecture/hero.jpg')} />
          <Wrapper>
            <CenterLine
              tabTitleHeight={312}
              deskTitleHeight={400}
              lessDesktopBottom
            />
            <DoubleParagraph
              title={t.architecture.collage.title}
              leftText={t.architecture.collage.content[1]}
              rightText={t.architecture.collage.content[0]}
              lessPadding
            />
          </Wrapper>
          <FullImage
            img={require('src/assets/images/architecture/architect_building.jpg')}
            alt="Architect Building"
            morePadding
          />
          <TitleContent
            title={t.architecture.titleContent.title}
            content={t.architecture.titleContent.text}
            largeMargin
          />
          <FullImage
            img={require('src/assets/images/home/homepage_architecture_crop.jpg')}
            alt="Architect Waterhero"
          />
          <NextPage
            text={t.architecture.nextPage}
            image={require('src/assets/images/architecture/link_bg_lifestyle.jpg')}
            to={`${getLocaleURL()}/lifestyle`}
          />
        </Page>
      )}
    </Intl>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

export default Architecture;
