import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwMobile, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Wechat = ({ dark, fixed }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLogo = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Root>
      <SVG
        width="24"
        height="20"
        viewBox="0 0 24 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleLogo}
        active={isOpen}
        dark={dark}
      >
        <g clip-path="url(#clip0_779_905)">
          <path
            d="M2.55469 15.7158L5.51953 14.2275C6.58594 14.4385 7.42969 14.6494 8.49609 14.6494C8.76562 14.6494 9.02344 14.6377 9.29297 14.6143C9.11719 14.0518 9.02344 13.4541 9.02344 12.833C9.02344 9.12988 12.2109 6.11816 16.2422 6.11816C16.5234 6.11816 16.793 6.12988 17.0625 6.16504C16.3359 2.74316 12.668 0.200195 8.49609 0.200195C3.82031 0.200195 0 3.37598 0 7.41895C0 9.75098 1.27734 11.6729 3.39844 13.1611L2.55469 15.7158ZM5.73047 5.93066C5.09766 5.93066 4.45312 5.50879 4.45312 4.87598C4.45312 4.23145 5.09766 3.80957 5.73047 3.80957C6.375 3.80957 6.79688 4.23145 6.79688 4.87598C6.79688 5.50879 6.375 5.93066 5.73047 5.93066ZM11.6836 5.93066C11.0391 5.93066 10.4062 5.50879 10.4062 4.87598C10.4062 4.23145 11.0391 3.80957 11.6836 3.80957C12.3164 3.80957 12.7383 4.23145 12.7383 4.87598C12.7383 5.50879 12.3164 5.93066 11.6836 5.93066ZM21.668 19.7471L21.0234 17.626C22.7344 16.3486 24 14.6494 24 12.7393C24 9.34082 20.6016 6.5752 16.7812 6.5752C12.7383 6.5752 9.5625 9.34082 9.5625 12.7393C9.5625 16.1377 12.7383 18.9033 16.7812 18.9033C17.625 18.9033 18.4805 18.6807 19.3359 18.4697L21.668 19.7471ZM14.4375 11.6729C14.0156 11.6729 13.5938 11.251 13.5938 10.8174C13.5938 10.3955 14.0156 9.97363 14.4375 9.97363C15.082 9.97363 15.5039 10.3955 15.5039 10.8174C15.5039 11.251 15.082 11.6729 14.4375 11.6729ZM19.1133 11.6729C18.6914 11.6729 18.2695 11.251 18.2695 10.8174C18.2695 10.3955 18.6914 9.97363 19.1133 9.97363C19.7461 9.97363 20.1797 10.3955 20.1797 10.8174C20.1797 11.251 19.7461 11.6729 19.1133 11.6729Z"
            fill="#EDEDEC"
          />
        </g>
        <defs>
          <clipPath id="clip0_779_905">
            <rect width="24" height="20" fill="white" />
          </clipPath>
        </defs>
      </SVG>
      <Popup active={isOpen} fixed={fixed} className="popup">
        <h2>
          Follow Landa
          <br /> on WeChat
          <br />
          <br />
          <span className="bold">LandaGlobaL</span>
        </h2>
        <QR
          src={require('src/assets/images/global/landa-qr-code.jpg')}
          alt="QR Wechat code"
        />
        <Close
          src={require('src/assets/images/global/open_close_icon.svg')}
          alt="close"
          onClick={handleLogo}
        />
      </Popup>
    </Root>
  );
};

Wechat.propTypes = {
  dark: PropTypes.bool,
  fixed: PropTypes.bool,
};

const Root = styled.div`
  position: relative;
`;

const Popup = styled.div`
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  right: ${(props) => (props.fixed ? '50%' : vwMobile(9))};
  bottom: ${(props) => (props.fixed ? '50%' : `calc(100% + ${vwMobile(11)})`)};
  transform: ${(props) => (props.fixed ? 'translate(50%, 50%)' : 'none')};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${({ theme }) => theme.color.cashDark};
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
  transition: 0.4s ease-out;
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? 'all' : 'none')};
  ${vw('width', 280, 280)}
  ${vw('height', 392, 392, 380)}
  ${vw('padding-top', 40)}
  ${vw('padding-bottom', 20, 20, 20)}
  .bold {
    font-weight: normal;
  }
  h2 {
    color: black;
    ${vw('font-size', 12, 12, 18)}
    ${vw('letter-spacing', 1, 1, 2)}
    ${vw('line-height', 14, 14, 21)}
  }
  img {
    height: auto;
  }
  @media ${media.tablet} {
    position: fixed;
    right: auto;
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  @media ${media.desktop} {
    position: absolute;
    top: auto;
    bottom: ${`calc(100% + ${vwDesktop(45)})`};
    transform: translateX(-50%);
  }
`;

const SVG = styled.svg`
  path,
  rect {
    fill: ${(props) =>
      props.active
        ? props.theme.color.yellow
        : props.dark
        ? props.theme.color.granite
        : props.theme.color.cash};
  }
  @media ${media.desktop} {
    cursor: pointer;
    path,
    rect {
      transition: 0.4s ease-out;
    }
    &:hover path,
    &:hover rect {
      fill: ${({ theme }) => theme.color.yellow};
    }
    &:hover + .popup {
      opacity: 1;
    }
  }
`;

const QR = styled.img`
  display: block;
  ${vw('width', 200)}
  ${vw('margin-top', 20)}
  ${vw('margin-bottom', 20)}
`;

const Close = styled.img`
  display: block;
  transform: rotate(45deg);
  ${vw('width', 36)}
  @media ${media.desktop} {
    display: none;
  }
`;

export default Wechat;
