import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

import Intl from 'components/Intl';
import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const ColourScheme = (props) => {
  const { content, reverse, images } = props;

  const [activeContent, setActiveContent] = useState(0);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handlePrev(),
    onSwipedRight: () => handleNext(),
  });

  const handleActiveContent = (i) => {
    setActiveContent(i);
  };

  const handleNext = () => {
    if (activeContent === content.length - 1) {
      setActiveContent(0);
    } else {
      setActiveContent(activeContent + 1);
    }
  };

  const handlePrev = () => {
    if (activeContent === 0) {
      setActiveContent(content.length - 1);
    } else {
      setActiveContent(activeContent - 1);
    }
  };

  return (
    <Intl>
      {(t) => (
        <FadeUp>
          <Root>
            <Media reverse={reverse}>
              <Image {...swipeHandlers}>
                <Arrow onClick={handlePrev}>
                  <img
                    src={require('src/assets/images/global/form_dropdown_arrow_thick_cash.svg')}
                    alt="Scroll Left"
                  />
                </Arrow>
                {images.map((image, index) => (
                  <img
                    className="content-image"
                    src={image}
                    alt=""
                    key={index}
                    style={{
                      opacity: index === activeContent || index === 0 ? 1 : 0,
                    }}
                  />
                ))}
                <Arrow right onClick={handleNext}>
                  <img
                    src={require('src/assets/images/global/form_dropdown_arrow_thick_cash.svg')}
                    alt="Scroll Right"
                  />
                </Arrow>
              </Image>
              <Wrapper>
                <p className="strapline granite uppercase mobile">
                  {t.residences.schemes.colorScheme}
                </p>
                <p className="strapline granite uppercase tablet">
                  {t.residences.schemes.colorSchemes}
                </p>
                <Buttons>
                  {content.map((item, index) => (
                    <Button
                      key={index}
                      className="button"
                      active={index === activeContent}
                      onClick={() => handleActiveContent(index)}
                    >
                      {item}
                    </Button>
                  ))}
                </Buttons>
              </Wrapper>
            </Media>
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

ColourScheme.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  content: PropTypes.array,
  images: PropTypes.array,
  reverse: PropTypes.bool,
};

const Root = styled.div`
  ${vw('padding-left', 0, 40, 200)}
  ${vw('padding-right', 0, 40, 200)}
  ${vw('margin-bottom', 60, 120, 160)}
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

// const Content = styled.div`
//   ${vw('padding-left', 18, 0)}
//   ${vw('padding-right', 18, 0)}
//   ${vw('margin-bottom', 15, 30, 0)}
//   ${vw('width', '100%', 'auto', 340)}
//   h1 {
//     ${vw('margin-bottom', 15, 30, 40)}
//   }
//   p {
//     ${vw('width', '100%', 450, '100%')}
//   }
//   @media ${media.tablet} {
//     text-align: center;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
//   @media ${media.desktop} {
//     text-align: ${(props) => (props.reverse ? 'left' : 'right')};
//     order: ${(props) => (props.reverse ? 2 : 1)};
//     h1 {
//       width: 100%;
//     }
//   }
// `;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .tablet {
    display: none;
  }
  .strapline {
    ${vw('margin-bottom', 8, 0)}
  }
  @media ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    .mobile {
      display: none;
    }
    .tablet {
      display: block;
    }
  }
`;

const Buttons = styled.div`
  position: relative;
  ${vw('width', 130, 'auto')}
  ${vw('height', 36, 'auto')}
  @media ${media.tablet} {
    display: flex;
  }
`;

const Media = styled.div`
  overflow: hidden;
  width: 100%;
  @media ${media.desktop} {
    order: ${(props) => (props.reverse ? 1 : 2)};
  }
`;

const Image = styled.div`
  position: relative;
  width: 100%;
  ${vw('height', 200, 433, 680)}
  ${vw('margin-bottom', 12, 20)}
  .content-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: ${({ theme }) => theme.transition};
    &:not(:first-of-type) {
      z-index: 2;
    }
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: ${(props) =>
    props.right ? 'translate(60%, -50%)' : 'translate(-60%, -50%)'};
  left: ${(props) => (props.right ? 'auto' : 0)};
  right: ${(props) => (props.right ? 0 : 'auto')};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.granite};
  z-index: 5;
  ${vw('width', 60)}
  ${vw('height', 60)}
  img {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
      ${(props) => (props.right ? 'rotate(-90deg)' : 'rotate(90deg)')};
    height: auto;
    z-index: 5;
    ${vw('width', 14)}
    ${(props) => (props.right ? vw('left', 8) : vw('right', 8))}
  }
  @media ${media.tablet} {
    display: none;
  }
`;

const Button = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: ${({ theme }) => theme.transition};
  background-color: ${({ theme }) => theme.color.granite};
  color: ${({ theme }) => theme.color.cash};
  display: flex;
  justify-content: center;
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? 'all' : 'none')};
  cursor: pointer;
  ${vw('padding-top', 12, 8, 9)}
  ${vw('padding-bottom', 8, 7, 6)}
  ${vw('padding-left', 2, 12)}
  ${vw('padding-right', 2, 12)}
  ${vw('margin-right', 0, 12)}
  &:last-of-type {
    margin-right: 0;
  }
  p {
    transition: ${({ theme }) => theme.transition};
  }
  @media ${media.tablet} {
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    background-color: ${(props) =>
      props.active ? props.theme.color.granite : 'transparent'};
    color: ${(props) =>
      props.active ? props.theme.color.cash : props.theme.color.granite};
    opacity: 1;
    pointer-events: all;
  }
  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.color.granite};
      color: ${({ theme }) => theme.color.cash};
    }
  }
`;

export default ColourScheme;
