export const collage = [
  [
    require('src/assets/images/neighbourhood/gallery_parkside_01.jpg'),
    require('src/assets/images/neighbourhood/gallery_parkside_02.jpg'),
    require('src/assets/images/neighbourhood/gallery_parkside_03.jpg'),
  ],
  require('src/assets/images/neighbourhood/cityside_collage.jpg'),
  require('src/assets/images/neighbourhood/parkside_collage.jpg'),
  [
    require('src/assets/images/neighbourhood/gallery_cityside_01.jpg'),
    require('src/assets/images/neighbourhood/gallery_cityside_02.jpg'),
    require('src/assets/images/neighbourhood/gallery_cityside_03.jpg'),
  ],
];
