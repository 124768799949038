import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';
import Hero from 'components/layout/Hero';
// import ContentCollage from 'components/layout/ContentCollage';
import CenterLine from 'components/layout/CenterLine';
import NextPage from 'components/layout/NextPage';
import DoubleParagraph from 'components/typography/DoubleParagraph';
import TitleContent from 'components/typography/TitleContent';
import FullImage from 'components/layout/FullImage';

// import { collage } from 'src/data/neighbourhood';

const Neighbourhood = () => {
  // const renderData = (content) => {
  //   let data = [];

  //   // this is to put the data from locale files in the same array with the images from the /data folder
  //   collage.map((item, index) => {
  //     let collageItem = {};
  //     typeof item === 'object'
  //       ? (collageItem.images = item)
  //       : (collageItem.image = item);
  //     content[index]
  //       ? (collageItem.text = content[index])
  //       : (collageItem.text = '');
  //     return data.push(collageItem);
  //   });

  //   return data;
  // };

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Page>
          <Hero image={require('src/assets/images/neighbourhood/hero.jpg')} />
          <Wrapper>
            <CenterLine
              tabTitleHeight={236}
              deskTitleHeight={304}
              lessDesktopBottom
            />
            <DoubleParagraph
              title={t.neighbourhood.intro.title}
              leftText={t.neighbourhood.intro.leftText}
              rightText={t.neighbourhood.intro.rightText}
              lessPadding
            />
          </Wrapper>
          <FullImage
            img={require('src/assets/images/neighbourhood/neighbourhood_stanleypark.jpg')}
            alt="Stanley Park"
            morePadding
          />
          <FullImage
            img={require('src/assets/images/neighbourhood/neighbourhood_hummingbird.jpg')}
            alt="Hummingbird Rendering"
          />
          {/* <Wrapper>
            <CenterLine tabTitleHeight={228} deskTitleHeight={188} />
            <ContentCollage
              title={t.neighbourhood.collage.title}
              content={renderData(t.neighbourhood.collage.content)}
            />
          </Wrapper> */}
          <TitleContent
            title={t.neighbourhood.collage.title}
            content={t.neighbourhood.map.text}
            noDesktopTitleBreak
          />
          <NextPage
            text={t.neighbourhood.nextPage}
            to={`${getLocaleURL()}/architecture`}
            image={require('src/assets/images/neighbourhood/link_bg_architecture.jpg')}
          />
        </Page>
      )}
    </Intl>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

export default Neighbourhood;
