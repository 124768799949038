import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Intl from 'components/Intl';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Logo = (props) => {
  const { dark, home, isMenuOpen } = props;
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root home={home} dark={dark} isMenuOpen={isMenuOpen}>
          <Link to={`${getLocaleURL()}/`}>
            <img
              src={
                dark
                  ? require('src/assets/images/logo/1818_logo_full_dark.svg')
                  : require('src/assets/images/logo/1818_logo_full.svg')
              }
              alt="1818 Alberni"
            />
          </Link>
        </Root>
      )}
    </Intl>
  );
};

Logo.propTypes = {
  dark: PropTypes.bool,
  home: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
};

const Root = styled.div`
  display: none;
  ${vw('top', 0, 30, 30)}
  img {
    display: block;
    height: auto;
    ${vw('width', 0, 80, 129)}
  }
  @media ${media.tablet} {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 101;
    transition: ${({ theme }) => theme.transition};
    opacity: ${(props) => (props.isMenuOpen ? 0 : 1)};
    pointer-events: ${(props) => (props.isMenuOpen ? 'none' : 'all')};
  }
`;

export default Logo;
