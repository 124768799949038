import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import FadeUp from 'components/animation/FadeUp';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/team';

const Grid = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          {data.map((item, index) => (
            <FadeUp>
              <Wrapper key={index} image={item}>
                <p className="team-project uppercase cash-light">
                  {t.team.grid.captions[index]}
                </p>
              </Wrapper>
            </FadeUp>
          ))}
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  display: grid;
  grid-template-columns: ${vwMobile(136)} ${vwMobile(136)};
  grid-gap: ${vwMobile(12)};
  padding: 0 ${vwMobile(18)};
  margin-bottom: ${vwMobile(60)};
  @media ${media.tablet} {
    grid-template-columns: ${vwTablet(216)} ${vwTablet(216)} ${vwTablet(216)};
    grid-gap: ${vwTablet(20)};
    padding: 0 ${vwTablet(40)};
    margin-bottom: ${vwTablet(120)};
  }
  @media ${media.desktop} {
    grid-template-columns: ${vwDesktop(378)} ${vwDesktop(378)} ${vwDesktop(378)};
    grid-gap: ${vwDesktop(40)};
    padding: 0 ${vwDesktop(273)};
    margin-bottom: ${vwDesktop(160)};
  }
`;

const Wrapper = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  width: 100%;
  white-space: nowrap;
  ${vw('height', 136, 216, 378)}
  ${vw('padding-top', 10, 16, 30)}
  ${vw('padding-left', 4, 10, 20)}
  ${vw('padding-right', 4, 10, 20)}
`;

export default Grid;
