import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import { buildPath } from 'src/config/app.conf';

const DownloadButtons = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Button
            text={
              <>
                <span>{t.residences.download.download}</span>{' '}
                {t.residences.download.button1}
              </>
            }
            href={`${buildPath}/pdf/1818Alberni_FeatureSheet_2Bedroom.pdf`}
            ghost
          />
          <Button
            text={
              <>
                <span>{t.residences.download.download}</span>{' '}
                {t.residences.download.button2}
              </>
            }
            href={`${buildPath}/pdf/1818Alberni_FeatureSheet_3Bedroom.pdf`}
            ghost
          />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('margin-bottom', 60, 120, 160)}
  a span {
    display: none;
  }
  a:first-of-type {
    ${vw('margin-bottom', 25, 40, 0)}
    ${vw('margin-right', 0, 0, 80)}
  }
  @media ${media.tablet} {
    a span {
      display: inline;
    }
  }
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: center;
  }
`;

export default DownloadButtons;
