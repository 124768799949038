import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from 'components/layout/Container';
import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const FlexContent = (props) => {
  const {
    title,
    text,
    image,
    reverse,
    bottomAlign,
    logo,
    logoWidth,
    logoMobile,
    largeMargin,
    smallMargin,
  } = props;
  return (
    <FadeUp>
      <Container>
        <Root
          bottomAlign={bottomAlign}
          largeMargin={largeMargin}
          smallMargin={smallMargin}
        >
          <Image src={image} alt="" reverse={reverse} />
          <Wrapper reverse={reverse}>
            {logo && (
              <Logo
                src={logo}
                alt=""
                width={logoWidth}
                mobileWidth={logoMobile}
              />
            )}
            {title && <h1 className="grante">{title}</h1>}
            <p className="grante">{text}</p>
          </Wrapper>
        </Root>
      </Container>
    </FadeUp>
  );
};

FlexContent.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  logo: PropTypes.string,
  logoWidth: PropTypes.number,
  logoMobile: PropTypes.number,
  reverse: PropTypes.bool,
  bottomAlign: PropTypes.bool,
  smallMargin: PropTypes.bool,
};

const Root = styled.div`
  ${vw('padding-left', 0, 0, 37)}
  ${vw('padding-right', 0, 0, 37)}
  ${(props) => {
    if (props.largeMargin) {
      return vw('margin-bottom', 120, 160, 240);
    } else if (props.smallMargin) {
      return vw('margin-bottom', 60, 80);
    } else {
      return vw('margin-bottom', 60, 80, 160);
    }
  }}
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: ${(props) => (props.bottomAlign ? 'flex-end' : 'flex-start')};
  }
`;

const Wrapper = styled.div`
  ${vw('width', '100%', 290, 580)}
  h1 {
    ${vw('margin-top', 15, 0)}
    ${vw('margin-bottom', 15, 30, 40)}
  }
  p {
    ${vw('width', '100%', '100%', 340)}
  }
  @media ${media.tablet} {
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.reverse ? 'flex-end' : 'flex-start')};
    order: ${(props) => (props.reverse ? 1 : 2)};
    text-align: ${(props) => (props.reverse ? 'right' : 'left')};
  }
`;

const Image = styled.img`
  display: block;
  height: auto;
  ${vw('width', '100%', 290, 580)}
  @media ${media.tablet} {
    order: ${(props) => (props.reverse ? 2 : 1)};
  }
`;

const Logo = styled.img`
  display: block;
  ${vw('margin-top', 15, 0)}
  ${vw('margin-bottom', 15, 30, 40)}
  ${(props) => vw('width', props.mobileWidth, props.width)}
`;

export default FlexContent;
