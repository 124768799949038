import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';
import DoubleParagraph from 'components/typography/DoubleParagraph';
import FlexContent from 'components/layout/FlexContent';
import CenterLine from 'components/layout/CenterLine';
import Grid from './sections/Grid';

import vw from 'src/styles/utils';

const Team = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Page>
          <Space />
          <Wrapper>
            <CenterLine tabTitleHeight={499} deskTitleHeight={567} />
            <DoubleParagraph
              title={t.team.intro.title}
              leftText={t.team.intro.leftText}
              rightText={t.team.intro.rightText}
              logo={require('src/assets/images/logo/landa_logo.svg')}
              logoAlt="Landa Global Properties"
            />
          </Wrapper>
          <Grid />
          <FlexContent
            text={t.team.content.rafii}
            logo={require('src/assets/images/logo/raffi_logo.png')}
            image={require('src/assets/images/team/raffi_project.jpg')}
            logoWidth={100}
            logoMobile={70}
            smallMargin
          />
          <FlexContent
            text={t.team.content.rennie}
            logo={require('src/assets/images/logo/logos_rennie_dark.svg')}
            image={require('src/assets/images/team/rennie_project.jpg')}
            reverse
            logoWidth={190}
            bottomAlign
            logoMobile={130}
          />
        </Page>
      )}
    </Intl>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const Space = styled.div`
  ${vw('padding-top', 60, 0)}
`;

export default Team;
