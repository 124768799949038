import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = (props) => {
  const { image, logo, mobileOverlay } = props;
  return (
    <Root id="hero">
      <Wrapper image={image} />
      <Gradient />
      {mobileOverlay && <Overlay />}
      {logo && (
        <Logo
          src={require('src/assets/images/logo/1818_logo_full.svg')}
          alt="1818 Alberni"
        />
      )}
    </Root>
  );
};

Hero.propTypes = {
  left: PropTypes.object,
  right: PropTypes.object,
  leftImage: PropTypes.string,
  rightImage: PropTypes.string,
};

const Root = styled.div`
  width: 100%;
  height: 48.64vh;
  display: flex;
  position: relative;
  @media ${media.tablet} {
    height: 56.25vh;
    img {
      display: none;
    }
  }
  @media ${media.desktop} {
    height: 100vh;
    min-height: ${vwDesktop(768)};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: visible;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    rgba(32, 33, 34, 0.6) 0%,
    rgba(32, 33, 34, 0) 28.7%,
    rgba(32, 33, 34, 0) 71.89%
  );
  mix-blend-mode: normal;
`;

const Logo = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  ${vw('bottom', 72)}
`;

const Overlay = styled(Gradient)`
  background: #202122;
  mix-blend-mode: multiply;
  opacity: 0.3;
  @media ${media.tablet} {
    display: none;
  }
`;

export default Hero;
