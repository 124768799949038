import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Intl from "components/Intl";
import Button from "components/Button";
import vw from "src/styles/utils";

import { ReactComponent as CloseSVG } from 'src/assets/images/popup/close.svg';

const Popup = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 3000)
  }, [])
  return (
    <Intl>
      {(t) => (
        <Root onClick={handleClose} open={open}>
          <Content onClick={(e) => e.stopPropagation()}>
            <Close onClick={handleClose} />
            <Wrapper>
              <h1>{t.popup.title}</h1>
              <p>{t.popup.text}</p>
              <Button text={t.popup.button} ghost to="/contact" handleClick={handleClose} />
            </Wrapper>
            <Border />
          </Content>
        </Root>
      )}
    </Intl>
  )
}

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;

  opacity: ${({ open }) => open ? 1 : 0};
  pointer-events: ${({ open }) => open ? 'auto' : 'none'};
  transition: all 0.7s;
`;


const Content = styled.div`
  ${vw('width', 284, 640, 950)}
  ${vw('height', 480, 438, 650)}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;

  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    ${vw('height', 136.6, 300, 236)}
    object-fit: cover;
    ${vw('object-position', 'bottom')}
  }
`;

const Wrapper = styled.div`
  height: 100%;
  ${vw('padding-top', 50, 100, 75)}
  ${vw('padding-left', 24, 64, 141)}
  ${vw('padding-right', 24, 64, 141)}
  ${vw('padding-bottom', 40, 70, 75)}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
  h1 {
    ${vw('padding-left', 12, 0, 0)}
    ${vw('padding-right', 12, 0, 0)}
    ${vw('margin-bottom', 20, 32, 40)}
    ${vw('font-size', 30, 30, 60)}
    ${vw('line-height', 38, 38, 76)}
  }
  p {
    ${vw('padding-left', 0, 40, 0)}
    ${vw('padding-right', 0, 40, 0)}
    ${vw('font-size', 14, 14, 18)}
    ${vw('line-height', 21, 21, 28)}
  }
  a {
    ${vw('margin-top', 40, 64, 80)}
  }
`;

const Close = styled(CloseSVG)`
  position: absolute;
  ${vw('width', 20)}
  ${vw('height', 20)}
  ${vw('top', 18, 40, 40)}
  ${vw('right', 18, 40, 40)}
`;

const Border = styled.div`
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  position: absolute;
  top: 8px;
  left: 8px;
  pointer-events: none;
  border: 4px solid ${({ theme }) => theme.color.granite};
`;

export default Popup;
