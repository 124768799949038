import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Lang = (props) => {
  const { dark, mobile, handleClose, isMenuOpen } = props;
  return (
    <Root mobile={mobile}>
      <Link
        to="/"
        className={`button lang uppercase ${
          dark && !isMenuOpen ? 'granite' : 'cash-light'
        }`}
        onClick={handleClose}
      >
        EN
      </Link>
      <span
        className={`button lang uppercase ${
          dark && !isMenuOpen ? 'granite' : 'cash-light'
        }`}
      >
        |
      </span>
      <Link
        to="/tc"
        className={`button lang uppercase ${
          dark && !isMenuOpen ? 'granite' : 'cash-light'
        }`}
        onClick={handleClose}
      >
        中文
      </Link>
    </Root>
  );
};

Lang.propTypes = {
  dark: PropTypes.bool,
  mobile: PropTypes.bool,
  handleClose: PropTypes.func,
};

const Root = styled.div`
  display: ${(props) => (props.mobile ? 'flex' : 'none')};
  align-items: center;
  a,
  span {
    transition: ${({ theme }) => theme.transition};
  }
  span {
    ${vw('margin-left', 10, 15)}
    ${vw('margin-right', 10, 15)}
  }
  @media ${media.tablet} {
    display: ${(props) => (props.mobile ? 'none' : 'flex')};
  }
`;

export default Lang;
