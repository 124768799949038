import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const LineLink = (props) => {
  const { dark, yellow, href, to, handleClick, text, yellowHover } = props;

  const handleTextColor = () => {
    if (dark) {
      return 'granite';
    } else if (yellow) {
      return 'yellow';
    } else {
      return 'cash-light';
    }
  };

  return (
    <Root
      as={to ? Link : 'a'}
      to={to}
      href={href}
      onClick={handleClick}
      target={href ? '_blank' : ''}
      rel={href ? 'noopener noreferrer' : ''}
      yellowHover={yellowHover}
      {...props}
    >
      <p className={`button uppercase ${handleTextColor()}`}>{text}</p>
      <span />
    </Root>
  );
};

LineLink.propTypes = {
  dark: PropTypes.bool,
  yellow: PropTypes.bool,
  yellowHover: PropTypes.bool,
  href: PropTypes.string,
  to: PropTypes.string,
  text: PropTypes.string,
  handleClick: PropTypes.func,
};

LineLink.defaultProps = {
  handleClick: (f) => f,
};

const Root = styled.a`
  display: block;
  transition: ${({ theme }) => theme.transition};
  width: fit-content;
  p {
    white-space: nowrap;
  }
  span {
    display: block;
    background-color: ${(props) =>
      props.yellowHover ? props.theme.color.yellow : props.theme.color.cash};
    width: 100%;
    transition: ${({ theme }) => theme.transition};
    ${vw('height', 2, 2, 3)}
    ${vw('margin-top', 5, 2, 5)}
  }
  @media ${media.desktop} {
    span {
      transform: scaleX(0);
      transform-origin: left;
    }
    &:hover span {
      transform: scaleX(1);
    }
  }
`;

export default LineLink;
