import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const Input = (props) => {
  const { label, name, id, required, type, pattern } = props;

  const [value, setValue] = useState('');

  return (
    <Root>
      <input
        type={type}
        name={name}
        id={id}
        className={`
          form content granite ${value !== '' && 'active'}`}
        required={required}
        onChange={(e) => setValue(e.target.value)}
        pattern={pattern}
      />
      <label htmlFor={id} className="form input granite">
        {label}
      </label>
      <span />
      <span className="large" />
    </Root>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.string,
  required: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
};

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  ${vw('width', '100%', '100%', 636)}
  ${vw('height', 63, 92, 92)}
  ${vw('margin-bottom', 25, 31, 31)}
  span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.color.granite};
    transition: 0.4s ease-out;
    &.large {
      transform: scaleY(0);
      transform-origin: bottom;
      ${vw('height', 3)}
    }
  }
  label {
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    left: 0;
    transition: 0.4s ease-out;
    cursor: text;
  }
  input {
    width: 100%;
    border: none;
    background-color: transparent;
    ${vw('margin-bottom', 6, 7)}
    &:-webkit-autofill {
      appearance: none;
      box-shadow: 0 0 0px 1000px transparent inset;
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  input:focus {
    background-color: transparent;
    outline: 0;
    border: none;
  }
  input:focus ~ span.large {
    transform: scaleY(1);
  }
  input:focus + label,
  input.active + label {
    top: 0;
    transform: translateY(0);
    ${vw('font-size', 12, 18)}
    ${vw('line-height', 14, 21)}
    ${vw('letter-spacing', 1, 2)}
  }
`;

export default Input;
