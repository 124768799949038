module.exports = {
  defaultLocale: 'en',
  locales: ['en', 'tc'],
  prerender: true,
  buildPath: '/1818alberni',
  analytics: {
    gtag: '',
    gtmId: 'GTM-PP6ZNS',
    optimizeId: '',
    hotjarId: '',
  },
  seo: {
    title: '1818 Alberni',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1760,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
};
