import React from 'react';

import Page from 'components/Page';
import Intro from './sections/Intro';
import Content from './sections/Content';

const Contact = () => {
  return (
    <Page>
      <Intro />
      <Content />
    </Page>
  );
};

export default Contact;
