import React from 'react';
import styled from 'styled-components';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = () => {
  return (
    <Root id="hero">
      <Video
        autoPlay
        playsInline
        muted
        loop
        src="https://player.vimeo.com/progressive_redirect/playback/705905241/rendition/1080p?loc=external&amp;signature=491474ccd14dc7db408263f3cdda3472e203bccc9291db284632fa0f0ef71e4d"
      />
      <Gradient />
      <Logo
        src={require('src/assets/images/logo/1818_logo_full.svg')}
        alt="1818 Alberni"
      />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 63.4vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  ${vw('padding-bottom', 72, 107, 0)}
  @media ${media.tablet} {
    height: 42.2vh;
  }
  @media ${media.desktop} {
    align-items: center;
    height: 100vh;
    min-height: ${vwDesktop(768)};
  }
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(
    180deg,
    rgba(32, 33, 34, 0.6) 0%,
    rgba(32, 33, 34, 0) 28.7%,
    rgba(32, 33, 34, 0) 71.89%
  );
  mix-blend-mode: normal;
  z-index: 2;
`;

const Logo = styled.img`
  display: block;
  z-index: 2;
  position: relative;
  ${vw('width', 180, 216, 414)}
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export default Hero;
