import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const Container = (props) => {
  return <Root>{props.children}</Root>;
};

const Root = styled.div`
  ${vw('padding-left', 18, 50, 200)}
  ${vw('padding-right', 18, 50, 200)}
`;

export default Container;
