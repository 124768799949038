import React from 'react';
import styled from 'styled-components';
import { useParams, Link, useLocation } from 'react-router-dom';

import Intl from 'components/Intl';
import Page from 'components/Page';
import Button from 'components/Button';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/plans';
import { buildPath } from 'src/config/app.conf';

const SinglePlan = () => {
  const { id } = useParams();
  const location = useLocation();

  const plan = data[id];

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Page>
          <Root>
            <Space />
            <StyledLink
              to={{
                pathname: `${getLocaleURL()}/floorplans`,
                state: {
                  activeLevel: location.state
                    ? location.state.activeLevel
                    : undefined,
                },
              }}
            >
              <img
                src={require('src/assets/images/floorplans/arrow.svg')}
                alt="See all plans"
              />
              <p className="button uppercase granite">
                {t.floorplans.plans.seeAll}
              </p>
            </StyledLink>
            {plan && (
              <>
                <Unit>
                  <h1 className="floorplans unit-name cash uppercase">
                    {plan.id}
                  </h1>
                </Unit>
                <Wrapper>
                  <Level desktop>
                    <h4 className="strapline normal uppercase granite">
                      {t.floorplans.levels.level}
                      <br /> {plan.level}{' '}
                      {t.floorplans.levels.levelLast &&
                        t.floorplans.levels.levelLast}
                    </h4>
                    <Plate>
                      <img
                        src={require(`src/assets/images/floorplans/plates/${plan.id}.svg`)}
                        alt={`Plate ${plan.id}`}
                      />
                    </Plate>
                    <North
                      src={require('src/assets/images/floorplans/north_icon.svg')}
                      alt="North Compass"
                    />
                  </Level>
                  <Image>
                    <img
                      src={require(`src/assets/images/floorplans/plans/${plan.id}@2x.png`)}
                      alt={`Unit ${plan.id}`}
                    />
                  </Image>
                  <Flex>
                    <Level>
                      <h4 className="strapline normal uppercase granite">
                        {t.floorplans.levels.level}
                        <br /> {plan.level}{' '}
                        {t.floorplans.levels.levelLast &&
                          t.floorplans.levels.levelLast}
                      </h4>
                      <Plate>
                        <img
                          src={require(`src/assets/images/floorplans/plates/${plan.id}.svg`)}
                          alt={`Plate ${plan.id}`}
                        />
                      </Plate>
                      <North
                        src={require('src/assets/images/floorplans/north_icon.svg')}
                        alt="North Compass"
                      />
                    </Level>
                    <div>
                      <Details margin>
                        <p className="strapline uppercase granite normal">
                          {plan.bed} {t.floorplans.plans.bed}{' '}
                          {plan.den && `+ ${t.floorplans.plans.den}`}{' '}
                          {plan.flex && `+ ${t.floorplans.plans.flex}`}
                        </p>
                        <p className="strapline uppercase granite normal">
                          {plan.bath} {t.floorplans.plans.bath}
                        </p>
                      </Details>
                      <Details>
                        <p className="strapline uppercase granite small-margin">
                          {t.floorplans.plans.interior} {plan.interior}{' '}
                          {t.floorplans.plans.sf}
                        </p>
                        <p className="strapline uppercase granite">
                          {t.floorplans.plans.exterior} {plan.exterior}{' '}
                          {t.floorplans.plans.sf}
                        </p>
                        <p className="strapline uppercase granite normal">
                          {t.floorplans.plans.total} {plan.total}{' '}
                          {t.floorplans.plans.sf}
                        </p>
                      </Details>
                      <Container>
                        <Button
                          text={t.floorplans.plans.download}
                          href={`${buildPath}/pdf/plans/${plan.id}.pdf`}
                        />
                      </Container>
                    </div>
                  </Flex>
                </Wrapper>
                <Disclaimer>
                  <p className="floorplans disclaimer granite">
                    {t.floorplans.disclaimer}
                  </p>
                </Disclaimer>
              </>
            )}
          </Root>
        </Page>
      )}
    </Intl>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-left', 12, 40, 60)}
  ${vw('padding-right', 12, 40, 60)}
  ${vw('margin-bottom', 80, 60)}
`;

const Space = styled.div`
  ${vw('padding-top', 120, 160, 240)}
`;

const Unit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.granite};
  z-index: 2;
  ${vw('width', 120, 200)}
  ${vw('height', 120, 200)}
  ${vw('margin-bottom', -60, -100)}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.cash};
  ${vw('padding-top', 75, 138, 120)}
  ${vw('padding-left', 15, 60, 186)}
  ${vw('padding-right', 15, 60, 115)}
  ${vw('padding-bottom', 0, 60, 20)}
  ${vw('margin-bottom', 50, 30, 60)}
  @media ${media.desktop} {
    width: 100%;
    flex-direction: row;
  }
`;

const Container = styled.div`
  ${vw('margin-bottom', -19, 0)}
`;

const Flex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${media.tablet} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  @media ${media.desktop} {
    display: flex;
    width: auto;
    align-self: flex-start;
    flex-direction: column;
  }
`;

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('width', '100%', '100%', 600)}
  ${vw('height', 284, 606, 640)}
  ${vw('margin-bottom', 15, 60, 0)}
  ${vw('margin-left', 0, 0, 185)}
  ${vw('margin-right', 0, 0, 119)}
  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
`;

const Plate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('width', 94, 150)}
  ${vw('height', 100, 160)}
  ${vw('margin-left', 22, 0)}
  ${vw('margin-right', 34, 0)}
  ${vw('margin-top', 0, 20)}
  ${vw('margin-bottom', 0, 40)}
  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
`;

const Level = styled.div`
  display: ${(props) => (props.desktop ? 'none' : 'flex')};
  align-items: center;
  ${vw('margin-bottom', 30, 0)}
  h4 {
    text-align: center;
  }
  @media ${media.tablet} {
    flex-direction: column;
    justify-content: center;
    h4 {
      br {
        display: none;
      }
    }
  }
  @media ${media.desktop} {
    display: ${(props) => (props.desktop ? 'flex' : 'none')};
  }
`;

const Details = styled.div`
  text-align: center;
  ${(props) =>
    props.margin
      ? vw('margin-bottom', 15, 60)
      : vw('margin-bottom', 30, 60, 251)}
  ${(props) => props.margin && vw('margin-top', 0, 0, 95)}
  .strapline {
    ${vw('margin-bottom', 8, 15)}
    &:last-of-type {
      margin-bottom: 0;
    }
    &.small-margin {
      ${vw('margin-bottom', 8, 9)}
    }
  }
  @media ${media.tablet} {
    text-align: left;
  }
`;

const Disclaimer = styled.div`
  text-align: center;
  ${vw('width', '100%', 452, 1083)}
`;

const North = styled.img`
  display: block;
  height: auto;
  ${vw('width', 18, 27)}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  align-self: flex-start;
  ${vw('margin-bottom', 60, 60, -55)}
  img {
    display: block;
    height: auto;
    ${vw('width', 33, 53)}
    ${vw('margin-right', 15, 40)}
  }
  @media ${media.desktop} {
    p {
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -2px;
        left: 0;
        transform: scaleX(0);
        background-color: ${({ theme }) => theme.color.yellow};
        transition: ${({ theme }) => theme.transition};
        transform-origin: left;
        width: 100%;
        ${vw('height', 3)}
      }
    }
    &:hover p::after {
      transform: scaleX(1);
    }
  }
`;

export default SinglePlan;
