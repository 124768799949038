import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const FadeGallery = (props) => {
  const { images } = props;

  const [activeImage, setActiveImage] = useState(0);

  const handleImage = useCallback(() => {
    if (activeImage === images.length - 1) {
      setActiveImage(0);
    } else {
      setActiveImage((activeImage) => activeImage + 1);
    }
  }, [activeImage, images.length]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleImage();
    }, 4000);
    return () => clearTimeout(timeout);
  }, [handleImage]);

  return (
    <Root>
      {images.map((item, index) => (
        <img
          src={item}
          alt=""
          style={{
            opacity: activeImage === index ? 1 : 0,
            pointerEvents: activeImage === index ? 'all' : 'none',
          }}
        />
      ))}
    </Root>
  );
};

FadeGallery.propTypes = {
  images: PropTypes.array,
};

const Root = styled.div`
  position: relative;
  ${vw('width', '100%', 200, 378)}
  ${vw('height', 189, 133, 251)}
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 1s ease;
  }
`;

export default FadeGallery;
