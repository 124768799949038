import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Activity = () => {
  return (
    <Intl>
      {(t) => (
        <FadeUp>
          <Root>
            {t.neighbourhood.activity.map((item, index) => (
              <Wrapper key={index}>
                <h1 className="granite">{item.title}</h1>
                <p className="granite">{item.text}</p>
              </Wrapper>
            ))}
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

const Root = styled.div`
  ${vw('padding-left', 60, 40, 200)}
  ${vw('padding-right', 60, 40, 200)}
  ${vw('margin-bottom', 0, 120, 160)}
  @media ${media.tablet} {
    display: flex;
  }
  @media ${media.desktop} {
    justify-content: space-between;
  }
`;

const Wrapper = styled.div`
  text-align: center;
  ${vw('margin-bottom', 30, 0)}
  ${vw('width', 'auto', 216, 248)}
  &:nth-of-type(2) {
    ${vw('margin-left', 0, 20, 0)}
    ${vw('margin-right', 0, 25, 0)}
    ${vw('width', 'auto', 211, 248)}
  }
  h1 {
    ${vw('margin-bottom', 15, 30)}
  }
`;

export default Activity;
