import React, { useRef } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import Input from 'components/form/Input';
import Radio from 'components/form/Radio';
import Select from 'components/form/Select';
import Consent from 'components/form/Consent';
import Button from 'components/Button';
import Intl from 'components/Intl';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import { buildPath } from 'src/config/app.conf';

const Form = () => {
  const $form = useRef();
  const $recaptcha = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!$recaptcha.current.props.grecaptcha.getResponse()) {
      e.preventDefault();
      $recaptcha.current.execute();
    } else {
      console.log('form already submitted.');
    }
  };

  const handleRecaptchaChange = () => {
    $form.current.submit();
  };

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root>
          <form
            id="registration-form"
            action="https://gateway.rennie.com/leads"
            method="POST"
            ref={$form}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div
              style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}
            >
              <label htmlFor="pardot_extra_field">Comments</label>
              <input
                type="text"
                id="pardot_extra_field"
                name="pardot_extra_field"
              />
            </div>

            <input name="00N3Z00000CeVvt" type="hidden" value="Project Site" />
            <input name="00N3Z00000CeVw9" type="hidden" value="Web Source" />
            <input
              name="00N3Z00000CeVw2"
              type="hidden"
              value="7013Z0000033kxXQAQ"
            />
            <input
              name="00N3Z00000CeVw3"
              type="hidden"
              value="a04f100000VqWcGAAV"
            />
            <input name="00N3Z00000CeYiQ" type="hidden" value="1" />
            <input
              name="00N3Z00000CeVw1"
              type="hidden"
              value="Web Registration"
            />
            <input
              name="retURL"
              type="hidden"
              value={
                window.location.origin +
                `${buildPath}${getLocaleURL()}/thankyou`
              }
            />
            <input name="language" type="hidden" value="en" />

            <Input
              name="first_name"
              id="first_name"
              label={t.register.firstName}
              pattern="[a-zA-Z ]+"
              required
            />
            <Input
              name="last_name"
              id="last_name"
              label={t.register.lastName}
              pattern="[a-zA-Z ]+"
              required
            />
            <Input
              name="email"
              id="email"
              label={t.register.email}
              required
              type="email"
            />
            <Input
              name="phone"
              type="tel"
              label={t.register.phone}
              pattern="^[0-9-+\s()]*$"
              required
            />
            <Input name="zip" id="zip" label={t.register.postal} required />
            <Radio
              label={t.register.bedType.label}
              name="00Nf100000CNWPU"
              id="00Nf100000CNWPU"
              options={t.register.bedType.options}
            />
            <Radio
              label={t.register.realtor.label}
              name="00Nj0000000gK0N"
              id="00Nj0000000gK0N"
              required
              options={t.register.realtor.options}
            />
            <Select
              label={t.register.howHeard.label}
              name="00Nj000000AITER"
              id="00Nj000000AITER"
              required
              options={t.register.howHeard.options}
            />
            <Consent />

            <p className="form disclaimer granite extra-disclaimer">
              The information you provide will be used by Landa and Rennie &amp;
              Associates Realty to enhance your home buying experience. Select
              members of our Sales Team are licensed Real Estate Professionals
              with Rennie &amp; Associates Realty and cannot provide
              representation to potential buyers of 1818 Alberni. For more
              details pertaining to Realtor representation, please{' '}
              <a
                href={`${buildPath}/assets/Disclosure-of-Representation-in-Trading-Services.pdf`}
                target="_blank"
                rel="noopener noreferrer"
                className="granite underline"
              >
                click here
              </a>
              .
            </p>

            <ReCAPTCHA
              ref={$recaptcha}
              size="invisible"
              sitekey="6LfzdjsdAAAAAM0YTGVDLlrDYwKcYJ8rYgIBEu2T"
              onChange={handleRecaptchaChange}
            />

            <Container>
              <Button text={t.register.submit} type="submit" large />
            </Container>
          </form>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  ${vw('margin-top', 60, 120)}
  ${vw('margin-bottom', 120, 120, 160)}
  ${vw('padding-left', 18, 63)}
  ${vw('padding-right', 18, 68)}
  .extra-disclaimer {
    ${vw('margin-bottom', 60, 85)}
  }
  .grecaptcha-badge {
    opacity: 0;
  }
  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    align-items: center;
    form {
      width: ${vwDesktop(636)};
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Form;
