import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const FullImage = (props) => {
  const {
    img,
    alt,
    morePadding,
    caption,
    disclaimer,
    largeBottomMargin,
    mediumBottomMargin,
    reverse,
  } = props;
  return (
    <FadeUp>
      <Root
        morePadding={morePadding}
        largeBottomMargin={largeBottomMargin}
        mediumBottomMargin={mediumBottomMargin}
        reverse={reverse}
      >
        <img src={img} alt={alt} />
        {caption && (
          <Caption>
            <p className="strapline granite uppercase">{caption}</p>
            <div>
              {disclaimer && (
                <p className="form disclaimer granite">{disclaimer}</p>
              )}
            </div>
          </Caption>
        )}
      </Root>
    </FadeUp>
  );
};

FullImage.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
  morePadding: PropTypes.bool,
  largeBottomMargin: PropTypes.bool,
  mediumBottomMargin: PropTypes.bool,
};

const Root = styled.div`
  ${(props) =>
    props.morePadding
      ? vw('padding-left', 18, 50, 340)
      : vw('padding-left', 18, 50, 200)}
  ${(props) =>
    props.morePadding
      ? vw('padding-right', 18, 50, 340)
      : vw('padding-right', 18, 50, 200)}
  ${(props) => {
    if (props.morePadding || props.mediumBottomMargin) {
      return vw(!props.reverse ? 'margin-bottom' : 'margin-top', 30, 60, 160);
    } else if (props.largeBottomMargin) {
      return vw(!props.reverse ? 'margin-bottom' : 'margin-top', 60, 120, 240);
    } else {
      return vw(!props.reverse ? 'margin-bottom' : 'margin-top', 30, 60, 80);
    }
  }}
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Caption = styled.div`
  width: 100%;
  ${vw('margin-top', 15, 20, 30)}
  .strapline {
    ${vw('width', 'auto', 241, 'auto')}
  }
  .disclaimer {
    ${vw('margin-top', 5, 0)}
    ${vw('width', 'auto', 241)}
  }
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    .disclaimer {
      text-align: right;
    }
  }
`;

export default FullImage;
