import React from 'react';

export default {
  switchLang: 'English',
  buttons: {
    homepage: '返回首页',
  },
  popup: {
    title: '正式动工',
    text: "我们非常开心地宣布，1818 Alberni 的建筑工程已正式展开，因此销售中心将迁往他处。在新销售中心开幕前，若您需要更多讯息，请随时与我们的团队联系。",
    button: '联系我们',
  },
  header: {
    register: '请即登记',
    bookAppt: '预约私人导览',
    menu: {
      items: [
        {
          text: '周边社区',
          to: '/neighbourhood',
        },
        {
          text: '建筑设计',
          to: '/architecture',
        },
        {
          text: '生活与服务',
          to: '/lifestyle',
        },
        {
          text: '住宅',
          to: '/residences',
        },
      ],
      subitems: [
        {
          text: '户型图',
          to: '/floorplans',
        },
        {
          text: '团队',
          to: '/team',
        },
        {
          text: '联系方式',
          to: '/contact',
        },
      ],
    },
  },
  home: {
    intro: {
      number: '',
      title: <>全球最美城市公園STANLEY PARK旁54户臻罕豪宅</>,
      leftText:
        '1818 Alberni，汇聚顶尖设计、精湛工艺、创新智能家居于一身，并携手世界顶级品牌Rolls-Royce Motor Cars Vancouver、Bang & Olufsen，传世之作，珍藏之选，仅限54套。紧邻全球最美城市公园Stanley Park与温哥华繁华市中心。动，享城市繁华；静，赏森林海湾。',
      rightText:
        '这个令人艳羡的地址，坐落在迷人的市中心社区，活力跃动的温哥华与世界著名的史丹利公园的野生自然环境相融合。在世上最宜居的城市里欣赏壮丽的市景和海景、在宁静的海堤漫步，享受舒适和便利的生活方式。',
    },
    content: {
      neighbourhood: {
        title: (
          <>
            雄踞温哥华
            <br />
            城市核心地段
          </>
        ),
        button: '社区',
        text: '举步之遥，尽享温哥华最丰富充实的都市活力生活',
      },
      architecture: {
        title: (
          <>
            惊艳的
            <br />
            建筑轮廓
          </>
        ),
        button: '建筑设计',
        text: '以极致现代美学风格见称的世界级建筑师 Foad Rafii 又一代表杰作。',
      },
      lifestyle: {
        title: (
          <>
            奢华的
            <br />
            生活体验
          </>
        ),
        button: '生活与服务',
        text: 'Landa与Rolls-Royce Motor Cars Vancouver强强联袂，为 1818 Alberni 的业主提供免费汽车服务，让业主获得便利奢华的超舒适出行体验。',
      },
      residences: {
        title: (
          <>
            创意
            <br />
            居住体验
          </>
        ),
        button: '住宅',
        text: '每套住宅的室内设计，均体现大楼独特的现代建筑风格和精致的美学理念。',
      },
    },
  },
  neighbourhood: {
    hero: {
      left: {
        title: '赏森林海湾',
        subtitle: '静',
        text: '尽享史丹利公园的自然风光之美。',
      },
      right: {
        title: '享都市繁华',
        subtitle: '动',
        text: '在充满活力的温哥华市中心开启城市探索之旅。',
      },
    },
    intro: {
      title: (
        <>
          生机勃勃
          <br />
          郁郁葱葱
        </>
      ),
      leftText:
        '1818 Alberni 地理位置优越，坐落于魅力十足的市中心街区，温哥华的时尚都市风格与史丹利公园的奇妙自然风光融为一体。',
      rightText:
        '在这座世界最宜居的城市，欣赏壮观的城市景观和无垠海洋，在宁静的日落下漫步，享受舒适便捷的生活方式。',
    },
    collage: {
      title: (
        <>
          雄踞温哥华
          <br />
          城市核心地段
        </>
      ),
      content: [
        '1818 Alberni 毗邻郁郁葱葱的 Lost Lagoon 和延绵的史丹利公园，俯瞰一千英亩的原始森林、花团锦簇的梦幻花园和曲折蜿蜒的小径，迷人景致令人流连忘返。',
        '清晨在公园散步，下午在英吉利海湾享受日光浴，然后到您最喜欢的餐厅享用一顿美味的晚餐——多么完美的一天！',
        '温哥华最具生机活力、最丰富充实的城市生活，与您的家园仅几步之遥。',
      ],
    },
    map: {
      title: <>繁华都市的核心地段</>,
      text: '1818 Alberni 拥有纵享这座城市格调生活的最好资源。从这里开始，开启丰富充实的生活旅程。',
      categories: [
        {
          title: '便捷',
          points: [
            {
              text: 'Whole Foods Market',
              top: '48.4%',
              left: '52.8%',
            },
            {
              text: 'Equinox',
              top: '45.6%',
              left: '67.6%',
            },
            {
              text: 'Urban Fare',
              top: '50.3%',
              left: '66.1%',
            },
          ],
        },
        {
          title: '娱乐',
          points: [
            {
              text: 'Vancouver Art Gallery',
              top: '48.8%',
              left: '74%',
            },
            {
              text: 'Queen Elizabeth Theatre',
              top: '45.6%',
              left: '85.3%',
            },
            {
              text: 'Parq Casino',
              top: '57.8%',
              left: '93.95%',
            },
            {
              text: 'Shangri-La & Chi the Spa',
              top: '47.6%',
              left: '70.6%',
            },
            {
              text: 'Rosewood Hotel Georgia',
              top: '45.6%',
              left: '75.7%',
            },
            {
              text: 'Fairmont Pacific Rim & Willow Stream Spa',
              top: '36.1%',
              left: '71.6%',
            },
            {
              text: 'Royal Vancouver Yacht Club',
              top: '31.5%',
              left: '49%',
            },
          ],
        },
        {
          title: '购物',
          points: [
            {
              text: 'Prada',
              top: '57.3%',
              left: '69.5%',
              line: {
                length: '4.1%',
                angle: 0,
                top: '53.2%',
                left: '70.5%',
              },
            },
            {
              text: 'Hermès',
              top: '45.9%',
              left: '72.8%',
            },
            {
              text: 'De Beers Jewellers',
              top: '57.3%',
              left: '66.5%',
              line: {
                length: '6%',
                angle: 40,
                top: '52.8%',
                left: '69%',
              },
            },
            {
              text: 'Louis Vuitton',
              top: '56%',
              left: '73.3%',
              line: {
                length: '6%',
                angle: 0,
                top: '50.2%',
                left: '74.3%',
              },
            },
            {
              text: 'Saint Laurent',
              top: '57.3%',
              left: '63.7%',
              line: {
                length: '9%',
                angle: 55,
                top: '51%',
                left: '67.5%',
              },
            },
            {
              text: 'Holt Renfrew',
              top: '41.6%',
              left: '77.4%',
            },
            {
              text: 'Nordstrom',
              top: '53.9%',
              left: '76.4%',
            },
            {
              text: 'Gucci',
              top: '44.9%',
              left: '62.8%',
              line: {
                length: '11%',
                angle: 120,
                top: '45%',
                left: '67.5%',
              },
            },
            {
              text: 'Hublot',
              top: '44.9%',
              left: '65.6%',
              line: {
                length: '7%',
                angle: 137,
                top: '46%',
                left: '68.5%',
              },
            },
            {
              text: 'Vacheron Constantin',
              top: '46.9%',
              left: '70.6%',
            },
            {
              text: 'Jaeger-LeCoultre',
              top: '61.5%',
              left: '71.5%',
              line: {
                length: '9.5%',
                angle: 5,
                top: '52.3%',
                left: '72.9%',
              },
            },
            {
              text: 'Rolex',
              top: '44.9%',
              left: '60%',
              line: {
                length: '15%',
                angle: 112,
                top: '42.5%',
                left: '66%',
              },
            },
            {
              text: 'Thom Browne',
              top: '57.3%',
              left: '60.8%',
              line: {
                length: '12%',
                angle: 66,
                top: '49.5%',
                left: '66%',
              },
            },
            {
              text: 'Brunello Cucinelli',
              top: '57.3%',
              left: '57.9%',
              line: {
                length: '17.2%',
                angle: 73,
                top: '47%',
                left: '64.5%',
              },
            },
            {
              text: 'Van Cleef & Arpels',
              top: '44.9%',
              left: '68.4%',
              line: {
                length: '5%',
                angle: 170,
                top: '47.5%',
                left: '70%',
              },
            },
          ],
        },
        {
          title: '餐厅',
          points: [
            {
              text: 'Kingyo Izakaya',
              top: '60.4%',
              left: '47.1%',
            },
            {
              text: 'Tetsu Sushi Bar',
              top: '54.7%',
              left: '47.1%',
            },
            {
              text: 'Lift Bar & Grill',
              top: '41.7%',
              left: '50.1%',
            },
            {
              text: 'Stanley Park Grill',
              top: '34%',
              left: '35.2%',
            },
            {
              text: 'Paul Bakery',
              top: '53.9%',
              left: '64.2%',
            },
            {
              text: 'Laduree',
              top: '50.4%',
              left: '62.5%',
            },
            {
              text: 'Breka Bakery',
              top: '54%',
              left: '61.7%',
            },
            {
              text: 'Thierry',
              top: '46.4%',
              left: '70.8%',
            },
            {
              text: 'Joe Fortes Seafood & Chop House',
              top: '52.9%',
              left: '67.5%',
            },
            {
              text: 'Black & Blue',
              top: '50.5%',
              left: '70.1%',
            },
            {
              text: 'Mott 32',
              top: '46.5%',
              left: '60.2%',
            },
            {
              text: 'Hawksworth',
              top: '46.4%',
              left: '74.5%',
            },
            {
              text: 'Nightingale',
              top: '38.4%',
              left: '72.9%',
            },
            {
              text: 'Coast',
              top: '50.5%',
              left: '72.5%',
            },
          ],
        },
      ],
    },
    activity: [
      {
        title: '散步',
        text: '在郁郁葱葱的史丹利公园散步，在英吉利海湾沙滩享受日光浴，或穿梭于城市繁华的街道。',
      },
      {
        title: '骑行',
        text: '沿海堤骑自行车，探索温哥华宽广的自行车道网络。',
      },
      {
        title: '驾车',
        text: '向北前往惠斯勒，向南到基思兰奴和其他目的地，快捷便利。 ',
      },
    ],
    nextPage: '建筑设计',
  },
  architecture: {
    hero: {
      left: {
        title: '启发灵感',
        subtitle: '建筑美学',
        text: '城市天际线的全新地标。',
      },
      right: {
        title: '高贵',
        subtitle: '优雅',
        text: '凸显凝聚力的当代风格。',
      },
    },
    collage: {
      title: (
        <>
          惊艳的
          <br />
          建筑轮廓
        </>
      ),
      content: [
        '以极致现代美学风格见称的世界级建筑师 Foad Rafii 又一代表杰作。',
        '1818 Alberni 将为温哥华著名的城市天际线再添一座地标性建筑，成为无缝连接这座繁华都市和史丹利公园的通道。',
      ],
    },
    titleContent: {
      title: (
        <>
          别具启发意义的
          <br />
          印象
        </>
      ),
      text: '1818 Alberni 有着极具立体几何感的建筑造型，优雅清晰的建筑轮廓，彰显 54 套宽敞住宅独有的特色。宽大的玻璃窗和私人露台，让住宅内部更加明亮，成为更加宜居的生活空间，每一个周到的设计细节都体现了卓越的工艺水准，为建筑平添一份无以伦比的优雅美感。',
    },
    nextPage: '生活与服务',
  },
  lifestyle: {
    hero: {
      left: {
        title: '体贴周到',
        subtitle: '诚挚真实',
        text: '令人惊叹的第一印象，配备精致的设施。',
      },
      right: {
        title: '无与伦比',
        subtitle: '极致完美',
        text: '免费服务，舒适生活。',
      },
    },
    intro: {
      title: (
        <>
          完美的服务，
          <br />
          体贴周到的便利设施
        </>
      ),
      leftText:
        '精心设计的便利设施，提供更自由宽敞的空间，让您享受健康的生活方式，自如探索幸福生活，尽情享受每一刻。',
      rightText:
        '项目的健身房配有先进的 Technogym 健身设备，供您保持身材，强健体魄。图书馆的休息廊风格舒适，带上一本书独享时光，或约上一两好友小聚。大楼礼宾部随时随地为您提供尽心的服务，让您享受到绝无仅有的便利和舒适，为您的生活增添一抹惬意的色彩。',
    },
    rollsRoyce: {
      title: (
        <>
          Rolls-Royce Motor Cars Vancouver：
          <br />
          完美的出行合作伙伴
        </>
      ),
      text: '劳斯莱斯是世界上设计、工艺和精制最高标准的同义词，更提供举世闻名的定制化服务。该品牌秉承其百年积淀和卓越传承，带来令人印象深刻的产品系列，同时不断推出更多灵感创新。',
      content: {
        title: (
          <>
            优雅
            <br />
            出行
          </>
        ),
        text: 'Landa 很高兴能与 Rolls-Royce Motor Cars Vancouver 合作，为 1818 Alberni 的业主提供专车服务，将便利性与极致舒的适性完美融合在一起。劳斯莱斯将为您提供汽车和司机服务，让您尽享宁静而奢华的出行体验。',
      },
    },
    partnerStyle: {
      title: "时尚搭挡",
      text: (<>
        我们与 Holt Renfrew 合作，提供代客泊车丶私人购物丶衣服量身定做与修改丶美容美妆丶美食等一系列的尊荣服务。
        <br /><br />
        让加拿大首屈一指的时尚销售专家，为您打造最合适的专属风格！
      </>)
    },
    collage1: {
      title: (
        <>
          体贴周到的
          <br />
          集成技术
        </>
      ),
      content: [
        {
          title: (
            <>
              1VALET <br />
              智能楼宇
              <br />
              设备
            </>
          ),
          text: '1818 Alberni 采用创新的无缝集成 1VALET 智能楼宇技术，全方位打造便利舒适的惬意生活。入口采用安全的无匙智能 (touchless)门禁系统，业主可以通过主入口的面部识别或自动车牌扫描进入，无需钥匙和电话。',
        },
        {
          list: [
            {
              title: '智能门禁系统',
              points: [
                '使用手机进行面部识别的安全无匙智能 (touchless)门禁系统',
                '可便捷地预设访客使用临时停车场、主入口和电梯的权限',
                '视频对讲功能，更加安全',
                '自动停车场门禁系统，安装车牌智能阅读器',
                '通过智能设备接收包裹派送通知，让您不会错过任何一个包裹',
              ],
            },
            {
              title: '智能社区',
              points: [
                '使用 1VALET 应用程序自主即时预订便利设施',
                '接收与大楼运营和社区更新相关的通知',
              ],
            },
          ],
        },
      ],
    },
    collage2: {
      content: [
        {
          title: (
            <>
              Latch
              <br />
              智能
              <br />
              门禁
              <br />
              系统
            </>
          ),
          text: '安全的无匙智能 (touchless)门禁系统，便捷的电梯入户设计，出入更便捷。 1818 Alberni 采用 Latch 智能门禁系统，无需钥匙即可从正门进入内部。',
        },
        {
          list: [
            {
              points: [
                '使用您的手机、Apple Watch 或个人入门密码就可以轻松进入',
                '为访客提供临时访问权限，无需共享钥匙或额外配制钥匙',
                'Latch 系统简单易操作，充满人性化设计，为您提供完全不需要钥匙的入门体验',
              ],
            },
          ],
        },
      ],
    },
    concierge: {
      title: (
        <>
          便民
          <br />
          礼宾
          <br />
          服务
        </>
      ),
      left: '每个人在生活中都有需要小帮忙的时刻。1818 Alberni 为业主提供真正的定制礼宾服务，确保您可以立刻获得所需要的支持。联系礼宾部，即可预订类型多样的专门服务，让您的日常生活更轻松，把特别重要的日子提升到另一个层次。服务内容包括：',
      right: {
        list: [
          '劳斯莱斯专职司机专车服务',
          '与 Holt Renfrew 合作提供各类顶级服务',
          '夏季游艇租赁',
          '洗衣、干洗、熨烫',
          '私人厨师',
          '宠物美容、带宠物散步、宠物看护',
          '免费迁入服务',
          '洗车',
          '室内设计咨询',
        ],
      },
    },
    higherLiving: {
      title: '奢华生活',
      text: '1818 Alberni 明亮的空间是让人最难忘的第一印象。高端材料打造的宽敞明亮空间，雕琢每一处细节，彰显无以伦比的设计活力。大楼的入口门厅设计奢华高贵，室内挑高天花板，高品位的当代艺术作品，与细节独特的品质相得益彰，让每一位访客都难以忘怀。',
    },
    fitness: {
      content: [
        {
          title: (
            <>
              先进
              <br />
              健身设施
            </>
          ),
          text: '业主专用健身设施，配备先进的 Technogym 健身设备，提供举重训练、有氧运动、动感单车和瑜伽等运动项目。业主还可以进行个人化训练，追踪健身计划进度。',
        },
        {
          text: '无需外出，在明亮舒适、设备齐全的空间，就可以做一些提升心跳的运动，也可以放松地伸展身体或进行康复训练。',
        },
      ],
      text: (
        <>
          业主专用健身设施，配备先进的 Technogym
          健身设备，提供举重训练、有氧运动、动感单车和瑜伽等运动项目。业主还可以进行个人化训练，追踪健身计划进度。
          <br />
          <br />
          无需外出，在明亮舒适、设备齐全的空间，就可以做一些提升心跳的运动，也可以放松地伸展身体或进行康复训练。
        </>
      ),
      title: (
        <>
          先进
          <br />
          健身设施
        </>
      ),
    },
    nextPage: '住宅',
  },
  residences: {
    hero: {
      left: {
        title: '魅力无限',
        subtitle: '定制化设计，',
        text: '设计精美的宽敞美宅。',
      },
      right: {
        title: '智能设备',
        subtitle: '轻松使用，',
        text: '智能家居，让每一天都与众不同。',
      },
    },
    intro: {
      title: '秀色可餐的景致',
      leftText:
        '1818 Alberni坐拥迷人风光，饱览四季变幻美不胜收的景观。站在住宅内，三个方向的无敌景致尽收眼底，既可以看到城市的繁华盛景，也可以欣赏公园内的自然风光。',
      rightText:
        '北面紧靠史丹利公园，雄伟的北岸山脉构成了一副生动的全景图。西南方向是英吉利海湾，日落在海面形成一幅色彩渐变的绚丽画卷。',
    },
    views: {
      caption: '21 楼东北方向的景观',
      disclaimer: '景观照片仅供展示参考，可能不准确。E.&O.E.',
    },
    interiors: {
      title: '定制室内设计',
      text: '每套住宅均呈现顶级室内设计风格，展示独特的现代建筑美感和精致的美学理念。所有空间均采用高品质材料和饰面打造，精心规划布局，为不同的场合提供最惬意自在的活动空间',
    },
    collage: {
      content: [
        {
          title: (
            <>
              B&amp;O
              <br />
              智能家居
              <br />
              解决方案
            </>
          ),
          text: '使用 Bang & Olufsen，只需单击按钮就可以营造理想的环境。一台方便操作的入墙式触摸屏，即可控制您的环境，能够根据自己的喜好创造一种氛围。',
        },
        {
          list: [
            {
              points: [
                '入墙式触摸屏配有中央控制器，可以对您的 B&O 智能家居系统进行监控和互动',
                '主区域自动无线照明和调光控制，带预置灯光场景：欢迎（全开）、再见（全关）、浪漫（调光）',
                '无论白天和夜晚，任何时间都可以通过调节客厅和餐厅的电动百叶窗，控制光线的明暗',
              ],
            },
          ],
        },
      ],
    },
    everydayLiving: {
      title: '非凡的日常生活体验',
      list: [
        [
          '定制意大利橱柜搁架系统，为衣柜提供充足的空间',
          '全屋铺设人字形实木复合地板',
          '隐蔽的宽敞洗衣机壁橱',
        ],
        [
          '客厅安装自动卷帘，实现极佳的便利和舒适度',
          '独一无二的手工意大利厨房橱柜',
          '集成 LED 照明',
        ],
        [
          '大理石厨房中岛，配木饰面橱柜和早餐吧台*',
          '瑞士制造 KWC 厨房水龙头和 Franke 台下水槽',
          '另有配备滤水系统的饮用水龙头',
        ],
      ],
      disclaimer: '* 3 bedroom residences only',
    },
    goodTaste: {
      title: (
        <>
          出众的品味
          <br />
          永远不过时
        </>
      ),
      text: (
        <>
          手工制作的意大利厨房是神奇魔法发生的好地方。三房户型配置大理石或石英石的天然石材上方橱柜，以及互补的天然石材台面和防溅挡板。
          <br />
          <br />
          配置同类最佳的Wolf和Sub-Zero家电组合，让精致的氛围锦上添花。
        </>
      ),
    },
    schemes: {
      colorScheme: '颜色方案',
      colorSchemes: '颜色方案',
      kitchen: {
        title: (
          <>
            多彩
            <br />
            生活空间
          </>
        ),
        text: '三种室内配色方案可选，每种都采用互补的现代色调。挑高天花板和宽大窗户，让更多自然光进入室内，完美的风景观赏视角，展示更宽敞舒适的室内设计美感。',
        options: ['象牙白', '翡翠绿', '古铜棕'],
      },
      bath: {
        title: <>平静舒适的空间</>,
        text: '水疗式主卧卫浴，让您在家中也可以畅享舒适放松的气围；顶级意大利悬挂式盥洗台及天然石台面令优雅的室内装潢更显大气。三房户型配置豪华的蒸气浴及独立的石材浴缸，为您提供宽敞的空间进行日常护理。地暖系统令一室四季如春。',
        options: ['象牙白', '翡翠绿', '古铜棕'],
      },
    },
    download: {
      download: '下载',
      button1: '2 卧室规格表',
      button2: '3 卧室规格表',
    },
    nextPage: '户型图',
  },
  team: {
    intro: {
      title: <>构筑更美好生活</>,
      leftText:
        'Landa 开发的住宅注重与所在社区的个性和特色相呼应，这也是它真正与众不同之处。每个项目无论是风格还是规模，都如此独特，秉承灵感独具的建筑和设计概念，精心打造每个细节，丰富周边的环境。',
      rightText:
        '1818 Alberni 也不例外，我们与备受赞誉的杰出建筑师和设计师合作，创建精致、舒适、创意而实用的住宅，从您入住的那一刻起，就会产生温馨的家的感觉。',
    },
    grid: {
      captions: [
        '1400 ALBERNI',
        'OVAL',
        '1650 ALBERNI',
        'RAPHAEL',
        'CASCADE CITY',
        'CHATEAU LAURIER',
      ],
    },
    content: {
      rafii:
        'Foad Rafii 是世界级建筑师，是打造温哥华优美城市天际线的领军者之一， 他在大型住宅和混合用途项目方面有丰富的经验，为北美城市带来一系列精美绝伦的当代建筑作品。',
      rennie:
        '40 多年来，rennie 一直与加西的顶级开发商合作，凭借其卓越的专业知识、值得信赖的经验和独特的以人为本的房地产销售策略理念，为无数社区带来高档住宅。',
    },
  },
  floorplans: {
    intro: {
      title: (
        <>
          格外
          <br />
          宽敞的
          <br />
          生活环境
        </>
      ),
      text: (
        <>
          住宅设计注重优化日常生活的效率和舒适度。开放式布局强调空间和动线，宽大玻璃窗带来足够的自然光线，赋予空间更多的生机与活力。
          <br />
          <br />
          请查看我们 11、14 和 19 层的推荐户型。如需了解其他户型，请电邮{' '}
          <a href="mailto:info@1818Alberni.com" className="granite">
            info@1818Alberni.com
          </a>{' '}
          联系我们的销售团队。
        </>
      ),
    },
    levels: {
      title: '选择楼层',
      level: '第',
      levelLast: '层',
    },
    plans: {
      bed: '卧室',
      bath: '浴室',
      flex: '多用途房',
      den: '书房',
      interior: '室内设计',
      exterior: '外观设计',
      total: '总计',
      sf: 'S.F.',
      view: '查看户型图',
      download: '下载户型图',
      seeAll: '查看所有户型图',
    },
    disclaimer:
      'This is not an offering for sale. Any such offering can only be made with a disclosure statement. The developer reserves the right to make changes and modifications to the information contained herein without prior notice. Dimensions, sizes, areas, specifications layout and materials are approximate only and subject to change without notice. E.&O.E.',
  },
  contact: {
    intro: {
      title: <>期待您的入住。</>,
      sales: '销售及一般查询',
      follow: '关注我们',
    },
    content: {
      gallery: '销售图库',
      hours: <>
        因建筑工程已经展开，原销售中心现已关闭。
        <br/><br/>
        新销售中心现正筹备中
      </>,
      button: '预约私人导览',
    },
  },
  register: {
    title: <>请即登记</>,
    subtitle: 'Now Selling',
    firstName: '名字*',
    lastName: '姓氏*',
    email: '电邮*',
    phone: '电话*',
    postal: '邮编*',
    bedType: {
      label: '倾向于哪种类型的房子',
      options: [
        {
          value: '2 Bed',
          label: '两房',
        },
        {
          value: '3 Bed',
          label: '三房',
        },
      ],
    },
    realtor: {
      label: '您是否为地产经纪？*',
      options: [
        {
          value: 'Yes',
          label: '是',
        },
        {
          value: 'No',
          label: '否',
        },
      ],
    },
    howHeard: {
      label: '您如何得知关于此项目的讯息*',
      options: [
        {
          value: 'Realtor',
          label: '地产经纪',
        },
        {
          value: 'Signage/Walk by/Drive by',
          label: '广告看板/路过',
        },
        {
          value: 'Word of Mouth',
          label: '亲朋好友',
        },
        {
          value: 'Direct Mailer',
          label: '直邮广告',
        },
        {
          value: 'Print Ad/Mail',
          label: '报纸广告',
        },
        {
          value: 'Radio',
          label: '电台',
        },
        {
          value: 'Online Advertisement',
          label: '网络广告',
        },
      ],
    },
    submit: '提交',
  },
  thankyou: {
    title: 'Thank you',
    text: '衷心感谢您对 1818 ALBERNI 的关注。我们会及时通知您与此项目相关的最新资讯',
  },
  notFound: {
    title: 'Sorry!  We apologize',
    text: 'Looks like something went wrong here. The page you are looking for does not exist.',
  },
};
