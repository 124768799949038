import React from 'react';

import Intl from 'components/Intl';
import SimplePage from 'components/layout/SimplePage';

const Thankyou = () => {
  return (
    <Intl>
      {(t) => <SimplePage title={t.thankyou.title} text={t.thankyou.text} />}
    </Intl>
  );
};

export default Thankyou;
