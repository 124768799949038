import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FadeUp from 'components/animation/FadeUp';
import Container from 'components/layout/Container';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const TitleContent = (props) => {
  const {
    title,
    content,
    list,
    largeMargin,
    xlMargin,
    disclaimer,
    noDesktopTitleBreak,
    largeText,
  } = props;
  return (
    <Container>
      <FadeUp>
        <Root
          largeMargin={largeMargin}
          xlMargin={xlMargin}
          list={list}
          noDesktopTitleBreak={noDesktopTitleBreak}
          largeText={largeText}
        >
          <h1 className="granite">{title}</h1>
          {list ? (
            <List>
              {list.map((item, index) => (
                <ul key={index}>
                  {item.map((point, p) => (
                    <li className="granite" key={p}>
                      {point}
                    </li>
                  ))}
                </ul>
              ))}
            </List>
          ) : (
            <p className="granite">{content}</p>
          )}
          {disclaimer && (
            <p className="granite floorplans disclaimer">{disclaimer}</p>
          )}
        </Root>
      </FadeUp>
    </Container>
  );
};

TitleContent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  disclaimer: PropTypes.string,
  list: PropTypes.array,
  largeMargin: PropTypes.bool,
  xlMargin: PropTypes.bool,
  noDesktopTitleBreak: PropTypes.bool,
};

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => {
    if (props.largeMargin) {
      return vw('margin-bottom', 60, 120, 160);
    } else if (props.xlMargin) {
      return vw('margin-bottom', 60, 120, 240);
    } else {
      return vw('margin-bottom', 30, 60, 80);
    }
  }}
  h1 {
    ${vw('margin-bottom', 15, 30, 40)}
  }
  p {
    ${(props) =>
      props.largeText
        ? vw('width', '100%', 450, 546)
        : vw('width', '100%', 450, 516)}
  }
  .disclaimer {
    ${vw('margin-top', 20, 30, 40)}
  }
  @media ${media.tablet} {
    align-items: center;
    text-align: ${(props) => (props.list ? 'left' : 'center')};
    h1 {
      text-align: center;
      br {
        display: ${(props) => (props.noDesktopTitleBreak ? 'none' : 'block')};
      }
    }
    .disclaimer {
      align-self: flex-end;
      width: auto;
    }
  }
`;

const List = styled.div`
  width: 100%;
  ${vw('margin-top', 0, 0, 40)}
  ul {
    ${vw('width', '100%', 216, 380)}
  }
  li {
    ${vw('margin-bottom', 5, 10, 15)}
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export default TitleContent;
