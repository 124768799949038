import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Container from './Container';
import FadeGallery from './FadeGallery';
import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

// EXPECTED CONTENT:
// should be either image or images. title & list are optional
// content: [
//   {
//     image: '',
//     images: [],
//     text: '',
//     title: '',
//     list: [
//       {
//         title: '',
//         points: ['']
//       }
//     ]
//   }
// ]

// the reverse prop is for rendering the collage with the first row having the image on the right, text on the left

const ContentCollage = (props) => {
  const { title, content, reverse, largeMargin } = props;

  // this renders out the content element depending on whats included, list, title and text, or just text
  const renderContent = (content) => {
    if (content.text && content.text !== '') {
      return (
        <div>
          {content.title && <h1 className="granite">{content.title}</h1>}
          <p className="granite">{content.text}</p>
        </div>
      );
    } else if (content.list) {
      return (
        <div className="list">
          {content.list.map((item, index) => (
            <div key={index} className="list-container">
              {item.title && (
                <h4 className="strapline granite uppercase">{item.title}</h4>
              )}
              <ul>
                {item.points.map((point, p) => (
                  <li className="granite" key={p}>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <Container>
      <Root largeMargin={largeMargin}>
        <FadeUp>
          {title && <h1 className="granite title">{title}</h1>}
          {content.map((item, index) => (
            <Row
              key={index}
              even={index % 2 === 0}
              reverse={reverse}
              index={index}
              last={index === content.length - 1}
            >
              <Media
                even={index % 2 === 0}
                reverse={reverse}
                first={index === 0}
                noText={item.text === ''}
                style={{
                  mixBlendMode: item.imageMultiply ? 'multiply' : 'none',
                }}
              >
                {item.images && <FadeGallery images={item.images} />}
                {item.image && (
                  <Image
                    src={item.image}
                    alt=""
                    even={index % 2 === 0}
                    reverse={reverse}
                    multiply={item.imageMultiply}
                    ta
                    tall={item.tallImage}
                    style={{
                      mixBlendMode: item.imageMultiply ? 'multiply' : 'none',
                    }}
                  />
                )}
              </Media>
              <Text even={index % 2 === 0} index={index} reverse={reverse}>
                {renderContent(item)}
              </Text>
            </Row>
          ))}
          {/* This isn't ideal, but it was the only way I could make each paragraph & image exactly 80px away from each other on desktop and keep the image/text relation on tablet & mobile */}
          <Wrapper>
            <DesktopColumn>
              <FadeUp>
                {content.map((item, index) =>
                  (reverse ? index % 2 !== 0 : index % 2 === 0) ? (
                    <Media
                      even={index % 2 === 0}
                      first={index === 0}
                      noText={item.text === ''}
                      reverse={reverse}
                      style={{
                        mixBlendMode: item.imageMultiply ? 'multiply' : 'none',
                      }}
                    >
                      {item.images && <FadeGallery images={item.images} />}
                      {item.image && (
                        <Image
                          src={item.image}
                          alt=""
                          even={index % 2 === 0}
                          reverse={reverse}
                          tall={item.tallImage}
                          multiply={item.imageMultiply}
                          style={{
                            mixBlendMode: item.imageMultiply
                              ? 'multiply'
                              : 'none',
                          }}
                        />
                      )}
                    </Media>
                  ) : (
                    <Text
                      even={index % 2 === 0}
                      index={index}
                      reverse={reverse}
                    >
                      {renderContent(item)}
                    </Text>
                  )
                )}
              </FadeUp>
            </DesktopColumn>
            <DesktopColumn right>
              <FadeUp>
                {content.map((item, index) =>
                  (reverse ? index % 2 !== 0 : index % 2 === 0) ? (
                    <Text
                      even={index % 2 === 0}
                      reverse={reverse}
                      index={index}
                    >
                      {renderContent(item)}
                    </Text>
                  ) : (
                    <Media
                      even={index % 2 === 0}
                      reverse={reverse}
                      first={index === 0}
                      noText={item.text === ''}
                      style={{
                        mixBlendMode: item.imageMultiply ? 'multiply' : 'none',
                      }}
                    >
                      {item.images && <FadeGallery images={item.images} />}
                      {item.image && (
                        <Image
                          src={item.image}
                          alt=""
                          even={index % 2 === 0}
                          reverse={reverse}
                          tall={item.tallImage}
                          multiply={item.imageMultiply}
                          style={{
                            mixBlendMode: item.imageMultiply
                              ? 'multiply'
                              : 'none',
                          }}
                        />
                      )}
                    </Media>
                  )
                )}
              </FadeUp>
            </DesktopColumn>
          </Wrapper>
        </FadeUp>
      </Root>
    </Container>
  );
};

ContentCollage.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
  reverse: PropTypes.bool,
  largeMargin: PropTypes.bool,
};

const Root = styled.div`
  ${vw('margin-top', 60, 120, 160)}
  ${(props) =>
    props.largeMargin
      ? vw('margin-bottom', 80, 160, 240)
      : vw('margin-bottom', 60, 120, 160)}
  ${vw('padding-left', 0, 0, 37)}
  ${vw('padding-right', 0, 0, 37)}
  .title {
    ${vw('margin-bottom', 15, 72, 80)}
  }
  @media ${media.tablet} {
    .title {
      text-align: center;
    }
  }
`;

const Wrapper = styled.div`
  display: none;
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
`;

const Row = styled.div`
  width: 100%;
  ${(props) => !props.last && vw('margin-bottom', 15, 30, 0)}
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media ${media.desktop} {
    display: none;
    align-items: ${(props) => (props.index === 0 ? 'flex-start' : 'center')};
  }
`;

const DesktopColumn = styled.div`
  display: none;
  ${vw('width', '100%', 290, 580)}
  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    align-items: ${(props) => (props.right ? 'flex-start' : 'flex-end')};
  }
`;

const Media = styled.div`
  transform: translateZ(0px);
  ${vw('width', '100%', 290, 580)}
  ${vw('margin-bottom', 15, 0)}
  @media ${media.tablet} {
    order: ${(props) => (props.even && props.reverse ? 2 : 1)};
    order: ${(props) => {
      if (props.even && props.reverse) {
        return 2;
      } else if (props.reverse) {
        return 1;
      } else if (!props.even && !props.reverse) {
        return 2;
      }
    }};
    display: flex;
    align-items: flex-start;
    justify-content: ${(props) =>
      props.even && !props.reverse ? 'flex-end' : 'flex-start'};
  }
  @media ${media.desktop} {
    overflow: visible;
    position: relative;
  }
`;

const Text = styled.div`
  ${vw('width', '100%', 290, 580)}
  p {
    ${vw('width', '100%', '100%', 340)}
    ${(props) => props.index !== 0 && vw('margin-top', 0, 0, 80)}
    ${vw('margin-bottom', 0, 0, 80)}
  }
  li {
    ${vw('width', '100%', '100%', 460)}
    ${vw('margin-bottom', 2, 5, 10)}
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  h1 {
    ${vw('margin-bottom', 15, 30, 40)}
  }
  .strapline {
    ${vw('margin-bottom', 10, 15, 20)}
  }
  .list {
    ${(props) => props.index !== 0 && vw('margin-top', 0, 0, 80)}
  }
  .list-container {
    ${vw('margin-bottom', 15, 30, 60)}
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media ${media.tablet} {
    text-align: ${(props) => {
      if (
        (props.even && !props.reverse) ||
        (!props.even && props.reverse) ||
        (!props.even && !props.reverse)
      ) {
        return 'left';
      } else {
        return 'right';
      }
    }};
    order: ${(props) => (props.even && !props.reverse ? 2 : 1)};
  }
  @media ${media.desktop} {
    position: relative;
    display: flex;
    justify-content: ${(props) => {
      if (props.even && !props.reverse) {
        return 'flex-start';
      } else if (props.even && props.reverse) {
        return 'flex-end';
      } else if (!props.even && props.reverse) {
        return 'flex-start';
      } else {
        return 'flex-end';
      }
    }};
  }
`;

const Image = styled.img`
  width: 100%;
  display: block;
  mix-blend-mode: ${(props) => (props.multiply ? 'multiply' : 'auto')};
  ${(props) =>
    props.tall
      ? vw('height', 'auto', 'auto', 800)
      : vw('height', 'auto', 'auto', 386)}
`;

export default ContentCollage;
