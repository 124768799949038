import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Button = (props) => {
  const { handleClick, text, to, href, large, register, ghost, white } = props;

  const handleAs = () => {
    if (href) {
      return 'a';
    } else if (to) {
      return Link;
    } else {
      return 'button';
    }
  };

  return (
    <Root
      onClick={handleClick}
      to={to}
      href={href}
      as={handleAs()}
      large={large}
      register={register}
      ghost={ghost}
      white={white}
      target={href ? '_blank' : ''}
      {...props}
    >
      <p className="button uppercase">{text}</p>
    </Root>
  );
};

Button.propTypes = {
  handleClick: PropTypes.func,
  text: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  large: PropTypes.bool,
  register: PropTypes.bool,
};

Button.defaultProps = {
  handleClick: (f) => f,
};

const Root = styled.button`
  display: block;
  width: fit-content;
  height: fit-content;
  white-space: nowrap;
  background-color: ${(props) =>
    props.ghost ? 'transparent' : props.theme.color.granite};
  color: ${(props) =>
    props.ghost && !props.white
      ? props.theme.color.granite
      : props.theme.color.cash};
  border: 1px solid
    ${(props) =>
      props.white ? props.theme.color.cash : props.theme.color.granite};
  cursor: pointer;
  transition: 0.4s ease-out;
  ${(props) =>
    props.register ? vw('padding-top', 18, 16) : vw('padding-top', 12, 16)}
  ${(props) =>
    props.register
      ? vw('padding-bottom', 16, 13)
      : vw('padding-bottom', 10, 13)}
  ${(props) =>
    props.large ? vw('padding-left', 83, 24) : vw('padding-left', 23, 24)}
  ${(props) =>
    props.large ? vw('padding-right', 83, 24) : vw('padding-right', 23, 24)}
  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.color.granite};
      color: ${({ theme }) => theme.color.yellow};
    }
  }
`;

export default Button;
