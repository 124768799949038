import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Intl from 'components/Intl';
import LineLink from 'components/typography/LineLink';
import Lang from './Lang';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Overlay = (props) => {
  const { isOpen, handleClose } = props;

  const [activeHoverItem, setActiveItem] = useState(null);
  const [activeHoverSubitem, setActiveHoverSubitem] = useState(null);

  const hover = (i, subitem) => {
    subitem ? setActiveHoverSubitem(i) : setActiveItem(i);
  };

  const hoverOut = (subitem) => {
    subitem ? setActiveHoverSubitem(null) : setActiveItem(null);
  };

  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root
          style={{
            opacity: isOpen ? 1 : 0,
            pointerEvents: isOpen ? 'all' : 'none',
          }}
        >
          <Background
            src={require('src/assets/images/global/8_menu.svg')}
            alt="Eight"
          />
          <Wrapper>
            <Link to={`/${getLocaleURL()}`} onClick={handleClose}>
              <Logo
                src={require('src/assets/images/logo/1818_logo_vertical.svg')}
                alt="1818 Alberni"
              />
            </Link>
            <Flex>
              <Main>
                {t.header.menu.items.map((item, index) => (
                  <StyledLink
                    to={`${getLocaleURL()}${item.to}`}
                    key={index}
                    className="cash uppercase menu main"
                    onClick={handleClose}
                    onMouseEnter={() => hover(index)}
                    onMouseOver={() => hover(index)}
                    onMouseLeave={() => hoverOut()}
                    onMouseOut={() => hoverOut()}
                    active={
                      activeHoverItem === null || activeHoverItem === index
                    }
                  >
                    {item.text}
                  </StyledLink>
                ))}
              </Main>
              <Div>
                <Container>
                  <Subitems>
                    {t.header.menu.subitems.map((item, index) => (
                      <StyledLink
                        to={`${getLocaleURL()}${item.to}`}
                        key={index}
                        className="cash-light uppercase menu subitem"
                        onClick={handleClose}
                        onMouseEnter={() => hover(index, true)}
                        onMouseOver={() => hover(index, true)}
                        onMouseLeave={() => hoverOut(true)}
                        onMouseOut={() => hoverOut(true)}
                        active={
                          activeHoverSubitem === null ||
                          activeHoverSubitem === index
                        }
                      >
                        {item.text}
                      </StyledLink>
                    ))}
                  </Subitems>
                  <Lang mobile />
                </Container>
                <LineLink
                  href="mailto:info@1818alberni.com"
                  text={t.header.bookAppt}
                />
              </Div>
            </Flex>
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

Overlay.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  background-color: ${({ theme }) => theme.color.graniteDark};
  transition: ${({ theme }) => theme.transition};
  display: flex;
  align-items: center;
  ${vw('padding-top', 0, 0, 128)}
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${vw('padding-left', 38, 80, 338)}
  ${vw('padding-right', 37, 80, 255)}
  ${vw('padding-top', 0, 220, 0)}
  @media ${media.tablet} {
    flex-direction: row;
    justify-content: flex-start;
  }
  @media ${media.desktop} {
    align-items: center;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  ${vw('margin-top', 60, 126, 0)}
  ${vw('margin-bottom', 40, 80, 222)}
  @media ${media.desktop} {
    align-items: flex-start;
  }
`;

const Flex = styled.div`
  @media ${media.desktop} {
    position: relative;
    display: flex;
  }
`;

const Div = styled.div`
  @media ${media.desktop} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Main = styled.div`
  ${vw('margin-right', 0, 0, 180)}
  a {
    display: block;
    white-space: nowrap;
    ${vw('margin-bottom', 24, 60, 77)}
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Subitems = styled.div`
  a {
    display: block;
    ${vw('margin-bottom', 15, 30)}
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Background = styled.img`
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  opacity: 0.2;
  ${vw('right', 60, 240, 'auto')}
  @media ${media.desktop} {
    opacity: 0.5;
    left: 0;
  }
`;

const Logo = styled.img`
  display: none;
  ${vw('width', 0, 140, 148)}
  ${vw('margin-right', 0, 80, 120)}
  @media ${media.tablet} {
    display: block;
  }
`;

const StyledLink = styled(Link)`
  @media ${media.desktop} {
    transition: ${({ theme }) => theme.transition};
    opacity: ${(props) => (props.active ? 1 : 0.2)};
  }
`;

export default Overlay;
