import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';
import { useSelector } from 'react-redux';

const Ad = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const breakpoint = useSelector((state) => state.browser.is);

  return (
    <Root isOpen={isOpen}>
      <Wrapper>
        <Close onClick={() => setIsOpen(false)} isOpen={isOpen} />
        <a
          className="external"
          href="https://1818alberni.as.me/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Banner
            src={
              breakpoint.desktop
                ? require('src/assets/images/ad/talisman/desktop-ad-banner@2x.jpg')
                : breakpoint.tablet
                ? require('src/assets/images/ad/talisman/tablet-ad-banner@2x.jpg')
                : require('src/assets/images/ad/talisman/mobile-ad-banner@2x.jpg')
            }
            isOpen={isOpen}
          />
        </a>
      </Wrapper>
    </Root>
  );
};

Ad.propTypes = {};

export default Ad;

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 900;
  pointer-events: none;

  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: all 0.6s ease;

  a.external {
    position: absolute;
    z-index: -1;
    left: 50%;
    bottom: 0;
  }
`;

const Banner = styled.img`
  border: 1px solid #ebebe750;
  cursor: ${({ isOpen }) => (isOpen ? 'pointer' : 'default')};
  position: absolute;
  transition: all 0.6s ease;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  left: 50%;
  translate: -50%;
  z-index: 900;
  /* ${vw('bottom', '5%', '47%', 50 - 50 * 0.4)}; */
  bottom: 0;
  ${vw('max-height', 574 / 2, 352 / 2, 0)}
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};

  @media ${media.desktop} {
    max-height: 200px;
  }
`;

const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};

  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  translate: -50%;
  ${vw('bottom', '5%', '47%', 50 - 50 * 0.4)};
  ${vw('max-height', 574 / 2, 352 / 2, 0)}
  width: auto;

  ${vw('width', 285, 624, 0)}
  ${vw('height', 287, 176, 'auto')}
  z-index: ${(props) => (props.close ? 950 : 900)};

  @media ${media.desktop} {
    max-height: 200px;
    width: 709.09px;
    height: 200px;
  }
`;
