import React, { useState } from 'react';
import styled from 'styled-components';

import FadeUp from 'components/animation/FadeUp';
import Intl from 'components/Intl';
import MapImage from '../elements/MapImage';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Map = () => {
  const [activeCategory, setActiveCategory] = useState(0);

  const handleCategory = (index) => {
    setActiveCategory(parseInt(index));
  };

  return (
    <Intl>
      {(t) => (
        <FadeUp>
          <Root>
            <DesktopSelect>
              {t.neighbourhood.map.categories.map((category, index) => (
                <Button
                  key={index}
                  value={index}
                  className="button granite uppercase"
                  active={index === activeCategory}
                  onClick={() => handleCategory(index)}
                >
                  {category.title}
                </Button>
              ))}
            </DesktopSelect>
            <Container>
              <MapImage
                categories={t.neighbourhood.map.categories}
                activeCategory={activeCategory}
              />
              <Wrapper>
                <SelectContainer>
                  <Select
                    className="mobile-dropdown granite"
                    onChange={(e) => handleCategory(e.target.value)}
                  >
                    {t.neighbourhood.map.categories.map((category, index) => (
                      <option key={index} value={index}>
                        {category.title}
                      </option>
                    ))}
                  </Select>
                  <img
                    src={require('src/assets/images/global/form_dropdown_arrow_thick.svg')}
                    alt="Select Category"
                  />
                  <TabletSelect>
                    {t.neighbourhood.map.categories.map((category, index) => (
                      <Button
                        key={index}
                        value={index}
                        className="button granite uppercase"
                        active={index === activeCategory}
                        onClick={() => handleCategory(index)}
                      >
                        {category.title}
                      </Button>
                    ))}
                  </TabletSelect>
                </SelectContainer>
                <List>
                  {t.neighbourhood.map.categories.map((category, index) => (
                    <Category active={index === activeCategory} key={index}>
                      <h1 className="granite small">{category.title}</h1>
                      {category.points.map((point, p) => (
                        <Point key={p}>
                          <p className="granite point-number">{p + 1}</p>
                          <p className="granite point-text">{point.text}</p>
                        </Point>
                      ))}
                    </Category>
                  ))}
                </List>
              </Wrapper>
            </Container>
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

const Root = styled.div`
  ${vw('margin-bottom', 90, 180, 160)}
`;

const Wrapper = styled.div`
  ${vw('padding-left', 0, 54, 160)}
  ${vw('padding-right', 0, 76, 160)}
  ${vw('margin-top', 0, 60, 120)}
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
  @media ${media.desktop} {
    flex: 1;
    justify-content: flex-start;
  }
`;

const Container = styled.div`
  @media ${media.desktop} {
    display: flex;
  }
`;

const SelectContainer = styled.div`
  position: relative;
  img {
    position: absolute;
    ${vw('width', 14)}
    ${vw('height', 7)}
    ${vw('top', 32)}
    ${vw('right', 18)}
  }
  @media ${media.tablet} {
    img {
      display: none;
    }
  }
`;

const TabletSelect = styled.div`
  display: none;
  @media ${media.tablet} {
    display: block;
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const DesktopSelect = styled.div`
  display: none;
  ${vw('margin-bottom', 0, 0, 40)}
  @media ${media.desktop} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Select = styled.select`
  appearance: none;
  border: none;
  background-color: ${({ theme }) => theme.color.yellow};
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  ${vw('height', 60)}
  ${vw('padding-left', 18)}
  ${vw('padding-right', 18)}
  ${vw('padding-bottom', 7)}
  &:focus {
    color: ${({ theme }) => theme.color.granite};
  }
  @media ${media.tablet} {
    display: none;
  }
`;

const List = styled.div`
  position: relative;
  ${vw('width', 'auto', 297, '100%')}
`;

const Category = styled.div`
  position: ${(props) => (props.active ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? 'all' : 'none')};
  transition: ${({ theme }) => theme.transition};
  transition-delay: ${(props) => (props.active ? '0.4s' : '0s')};
  ${vw('padding-top', 30, 0)}
  ${vw('padding-left', 18, 0)}
  ${vw('padding-right', 18, 0)}
  h1 {
    display: none;
    ${vw('margin-bottom', 0, 40)}
  }
  .point-number {
    display: block;
    ${vw('width', 25, 32)}
  }
  .point-text {
    ${vw('width', 259, 266)}
  }
  @media ${media.tablet} {
    h1 {
      display: block;
    }
  }
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  transition: ${({ theme }) => theme.transition};
  background-color: ${(props) =>
    props.active ? props.theme.color.yellow : 'transparent'};
  cursor: pointer;
  ${vw('width', 272, 272, 'auto')}
  ${vw('height', 50, 50, 'auto')}
  ${vw('padding-left', 24)}
  ${vw('padding-right', 24)}
  ${vw('padding-top', 0, 0, 16)}
  ${vw('padding-bottom', 0, 0, 13)}
  ${vw('margin-bottom', 24, 24, 0)}
  ${vw('margin-right', 0, 0, 40)}
  &:last-of-type {
    margin-bottom: 0;
    margin-right: 0;
  }
  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.color.yellow};
    }
  }
`;

const Point = styled.div`
  display: flex;
  p {
    width: fit-content;
  }
`;

export default Map;
