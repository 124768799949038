import React from 'react';
import styled from 'styled-components';

import Page from 'components/Page';
import Intl from 'components/Intl';
import Container from 'components/layout/Container';
import Form from './elements/Form';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Register = () => {
  return (
    <Intl>
      {(t) => (
        <Page>
          <Root>
            <Container>
              <h2 className="granite uppercase">{t.register.subtitle}</h2>
              <h1 className="granite">{t.register.title}</h1>
            </Container>
            <Form />
          </Root>
        </Page>
      )}
    </Intl>
  );
};

const Root = styled.div`
  ${vw('margin-top', 120, 160, 300)}
  h2 {
    ${vw('margin-bottom', 10, 20)}
  }
  @media ${media.tablet} {
    h1,
    h2 {
      text-align: center;
    }
  }
`;

export default Register;
