import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Loading = () => {
  const [hasLoad, setHasLoad] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    if (!window.__PRERENDERING) {
      setTimeout(() => {
        document.body.style.overflow = '';
        setHasLoad(true);
      }, 500);
    }
  }, []);

  return (
    <Root hasLoad={hasLoad}>
      <Wrapper>
        {' '}
        <Logo
          src={require('src/assets/images/logo/1818_logo_full.svg')}
          alt="1818 Alberni"
          hasLoad={hasLoad}
        />
      </Wrapper>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: ${({ theme }) => theme.color.graniteDark};
  transition: 1.4s;
  opacity: ${(props) => (props.hasLoad ? 0 : 1)};
  pointer-events: ${(props) => (props.hasLoad ? 'none' : 'all')};
  transition-delay: 1.5s;
`;

const Wrapper = styled.div`
  height: 63.4vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${vw('padding-bottom', 72, 107, 0)}
  @media ${media.tablet} {
    height: 42.2vh;
  }
  @media ${media.desktop} {
    align-items: center;
    height: 100vh;
    min-height: ${vwDesktop(768)};
  }
`;

const Logo = styled.img`
  display: block;
  opacity: ${(props) => (props.hasLoad ? 1 : 0)};
  transform-origin: center center;
  transition: ${({ theme }) => theme.transition};
  transition-delay: 0.5s;
  ${vw('width', 180, 216, 414)}
`;

export default Loading;
