import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Lang from './Lang';
import MenuButton from './MenuButton';
import Intl from 'components/Intl';
import LineLink from 'components/typography/LineLink';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Topbar = (props) => {
  const { isMenuOpen, handleMenu, handleCloseMenu, dark, home } = props;
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root dark={dark} isOpen={isMenuOpen}>
          <Lang
            handleClose={handleCloseMenu}
            dark={dark}
            isMenuOpen={isMenuOpen}
          />
          <Link to={`${getLocaleURL()}/`}>
            <Logo onClick={handleCloseMenu}>
              <img
                src={require('src/assets/images/logo/1818_logo_numbers_header.svg')}
                alt="1818"
                style={{ opacity: isMenuOpen || (!dark && home) ? 0 : 1 }}
              />
              <img
                src={require('src/assets/images/logo/1818_logo_numbers_header_white.svg')}
                alt="1818"
                style={{ opacity: isMenuOpen || (!dark && !home) ? 1 : 0 }}
              />
            </Logo>
          </Link>
          <Wrapper>
            <LineLink
              text={t.header.register}
              to={`${getLocaleURL()}/register`}
              handleClick={handleCloseMenu}
              dark={dark && !isMenuOpen}
              yellowHover
            />
            <MenuButton
              isOpen={isMenuOpen}
              handleClick={handleMenu}
              dark={dark}
            />
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

Topbar.propTypes = {
  isMenuOpen: PropTypes.bool,
  light: PropTypes.bool,
  home: PropTypes.bool,
  handleMenu: PropTypes.func,
  handleCloseMenu: PropTypes.func,
};

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background-color: ${(props) =>
    props.dark && !props.isOpen ? props.theme.color.cashLight : 'transparent'};
  transition: ${({ theme }) => theme.transition};
  ${vw('padding-left', 12, 30, 40)}
  ${vw('padding-right', 12, 30, 40)}
  ${vw('height', 60, 100, 128)}
  @media ${media.desktop} {
    background-color: transparent;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  a {
    ${vw('margin-bottom', 0, 0, -4)}
  }
`;

const Logo = styled.div`
  display: block;
  height: auto;
  position: relative;
  ${vw('width', 46)}
  img {
    display: block;
    height: auto;
    width: 100%;
    transition: ${({ theme }) => theme.transition};
    &:last-of-type {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  @media ${media.tablet} {
    display: none;
  }
`;

export default Topbar;
