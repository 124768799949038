import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Container from 'components/layout/Container';
import Facebook from 'components/logo/Facebook';
import Instagram from 'components/logo/Instagram';
import Wechat from 'components/logo/Wechat';
import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Intro = () => {
  return (
    <Intl>
      {(t) => (
        <Container>
          <FadeUp>
            <Root>
              <h1 className="granite">{t.contact.intro.title}</h1>
              <h2 className="granite uppercase large-margin">
                {t.contact.intro.sales}
              </h2>
              <Info>
                <a href="tel:+1-604-262-1818" className="granite small-margin">
                  <h2 className="granite">+1 604.262.1818</h2>
                </a>
                <a href="mailto:info@1818alberni.com" className="granite">
                  <h2 className="granite">info@1818alberni.com</h2>
                </a>
              </Info>
              <h2 className="granite uppercase medium-margin">
                {t.contact.intro.follow}
              </h2>
              <Social>
                <Facebook dark />
                <Instagram dark />
                <Wechat dark fixed />
              </Social>
            </Root>
          </FadeUp>
        </Container>
      )}
    </Intl>
  );
};

const Root = styled.div`
  ${vw('margin-top', 120, 160, 300)}
  ${vw('margin-bottom', 60, 120, 160)}
  h1 {
    ${vw('margin-bottom', 30, 60, 80)}
  }
  .small-margin {
    ${vw('margin-bottom', 8, 10)}
  }
  .medium-margin {
    ${vw('margin-bottom', 20, 27)}
  }
  .large-margin {
    ${vw('margin-bottom', 30, 40)}
  }
  @media ${media.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Info = styled.div`
  ${vw('margin-bottom', 30, 60, 80)}
  a {
    display: block;
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  a {
    ${vw('margin-right', 24, 30)}
  }
`;

export default Intro;
