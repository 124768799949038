import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';
import DoubleParagraph from 'components/typography/DoubleParagraph';
import CenterLine from 'components/layout/CenterLine';
import Hero from 'components/layout/Hero';
import ContentBlock from './elements/ContentBlock';
// import Ad from 'components/layout/Ad';

const Home = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => {
        return (
          <Page>
            {/* <Ad /> */}
            <Hero
              image={require('src/assets/images/home/hero_image.jpg')}
              logo
              mobileOverlay
            />
            <Wrapper>
              <CenterLine tabTitleHeight={479} deskTitleHeight={537} />
              <DoubleParagraph
                number={t.home.intro.number}
                title={t.home.intro.title}
                leftText={t.home.intro.leftText}
                rightText={t.home.intro.rightText}
              />
            </Wrapper>
            <ContentBlock
              content={t.home.content.lifestyle}
              image={require('src/assets/images/home/homepage_rr.jpg')}
              to={`${getLocaleURL()}/lifestyle`}
              vertical
            />
            <ContentBlock
              content={t.home.content.neighbourhood}
              image={require('src/assets/images/home/homepage_neighbourhood.jpg')}
              to={`${getLocaleURL()}/neighbourhood`}
              vertical
            />
            <ContentBlock
              content={t.home.content.architecture}
              image={require('src/assets/images/home/homepage_architecture_crop.jpg')}
              to={`${getLocaleURL()}/architecture`}
              vertical
            />
            <ContentBlock
              content={t.home.content.residences}
              image={require('src/assets/images/home/homepage_residences.jpg')}
              to={`${getLocaleURL()}/residences`}
              vertical
            />
          </Page>
        );
      }}
    </Intl>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

export default Home;
