import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const CenterLine = (props) => {
  const { tabTitleHeight, deskTitleHeight, lessDesktopBottom } = props;
  return (
    <Root
      tabTitleHeight={tabTitleHeight}
      deskTitleHeight={deskTitleHeight}
      lessDesktopBottom={lessDesktopBottom}
    />
  );
};

CenterLine.propTypes = {
  tabTitleHeight: PropTypes.number,
  deskTitleHeight: PropTypes.number,
  lessDesktopBottom: PropTypes.bool,
};

const Root = styled.div`
  display: none;
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.cashDark};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${(props) =>
    props.lessDesktopBottom
      ? vw('bottom', 0, -20, -40)
      : vw('bottom', 0, -40, -80)}
  @media ${media.tablet} {
    display: block;
    height: ${(props) => `calc(100% - ${vwTablet(props.tabTitleHeight)})`};
  }
  @media ${media.desktop} {
    height: ${(props) => `calc(100% - ${vwDesktop(props.deskTitleHeight)})`};
  }
`;

export default CenterLine;
