import React from 'react';
import styled from 'styled-components';

import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';

const Video = () => {
  return (
    <FadeUp>
      <Root>
        <video
          src="https://player.vimeo.com/progressive_redirect/playback/693317384/rendition/1080p?loc=external&amp;signature=d3e5444bc1a66b6fae2ce0f9b2954d20e2069ef19f762c60c9799e30c98aa115"
          autoPlay
          muted
          loop
          playsInline
        />
      </Root>
    </FadeUp>
  );
};

const Root = styled.div`
  width: 100%;
  ${vw('padding-left', 18, 50, 340)}
  ${vw('padding-right', 18, 50, 340)}
  video {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export default Video;
