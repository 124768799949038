import React from 'react';

import Intl from 'components/Intl';
import Page from 'components/Page';
import Hero from 'components/layout/Hero';
import FullImageContent from 'components/layout/FullImageContent';
import FullImage from 'components/layout/FullImage';
import NextPage from 'components/layout/NextPage';
import DoubleParagraph from 'components/typography/DoubleParagraph';
import TitleContent from 'components/typography/TitleContent';

const Lifestyle = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Page>
          <Hero image={require('src/assets/images/lifestyle/hero.jpg')} />
          <FullImageContent
            title={t.lifestyle.rollsRoyce.title}
            text={t.lifestyle.rollsRoyce.text}
            // textTitle={t.lifestyle.rollsRoyce.content.title}
            image={require('src/assets/images/lifestyle/image_full.jpg')}
            reverse
          />
          <FullImageContent
            noTopMargin
            title={t.lifestyle.partnerStyle.title}
            text={t.lifestyle.partnerStyle.text}
            image={require('src/assets/images/lifestyle/style.jpg')}
            reverse
          />
          <DoubleParagraph
            noTopMargin
            title={t.lifestyle.concierge.title}
            leftText={t.lifestyle.concierge.left}
            rightText={t.lifestyle.concierge.right}
            largeTitle
            largeRightSide
          />
          <FullImage
            img={require('src/assets/images/lifestyle/lobby.jpg')}
            alt="Lobby"
          />
          <TitleContent
            title={t.lifestyle.higherLiving.title}
            content={t.lifestyle.higherLiving.text}
            largeText
          />
          <FullImage
            img={require('src/assets/images/lifestyle/image_full-1.jpg')}
            alt="Prime Fitness"
          />
          <TitleContent
            title={t.lifestyle.fitness.title}
            content={t.lifestyle.fitness.text}
            largeText
          />
          <NextPage
            text={t.lifestyle.nextPage}
            image={require('src/assets/images/lifestyle/link_bg_residences.jpg')}
            to={`${getLocaleURL()}/residences`}
          />
        </Page>
      )}
    </Intl>
  );
};

export default Lifestyle;
