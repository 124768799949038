import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const NextPage = (props) => {
  const { image, text, to } = props;
  return (
    <Root>
      <Link to={to}>
        <Wrapper>
          <Background src={image} alt={text} className="background" />
          <h1 className="cash">{text}</h1>
          <Arrow
            src={require('src/assets/images/global/arrow_link_icon.svg')}
            alt={`to ${text}`}
          />
        </Wrapper>
      </Link>
    </Root>
  );
};

NextPage.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
};

const Root = styled.div`
  width: 100%;
  ${vw('padding-right', 18, 40, 200)}
  ${vw('padding-left', 18, 40, 200)}
  ${vw('margin-bottom', 30, 60, 160)}
  h1 {
    position: relative;
    z-index: 2;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  ${vw('height', 96, 240)}
  ${vw('padding-right', 15, 40, 80)}
  ${vw('padding-left', 15, 40, 80)}
  @media ${media.desktop} {
    &:hover .background {
      transform: translate(-50%, -50%) scale(1.15);
    }
  }
`;

const Background = styled.img`
  width: 200%;
  min-height: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.8s ease-out;
  @media ${media.desktop} {
    width: 100%;
  }
`;

const Arrow = styled.img`
  display: block;
  height: auto;
  position: relative;
  z-index: 2;
  ${vw('width', 30, 60)}
`;

export default NextPage;
