import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Select = (props) => {
  const { label, name, id, required, options } = props;

  return (
    <Root>
      <label htmlFor={id} className="form input granite">
        {label}
      </label>
      <Container>
        <select
          name={name}
          id={id}
          required={required}
          className="form content granite"
          placeholder="Select One"
        >
          <option value="">Select an option</option>
          {options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <img
          src={require('src/assets/images/global/form_dropdown_arrow.svg')}
          alt="Select"
        />
      </Container>
    </Root>
  );
};

Select.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array,
};

const Root = styled.div`
  width: 100%;
  position: relative;
  ${vw('margin-bottom', 50, 50, 35)}
  ${vw('padding-bottom', 0, 50)}
  @media ${media.tablet} {
    border-bottom: 1px solid ${({ theme }) => theme.color.granite};
  }
  label {
    display: block;
    ${vw('margin-bottom', 24, 10)}
  }
  select {
    width: 100%;
    background-color: transparent;
    position: relative;
    appearance: none;
    border-radius: 0;
    border: 1px solid ${({ theme }) => theme.color.granite};
    transition: 0.4s ease-out;
    ${vw('height', 52, 64)}
    ${vw('padding-top', 13, 11)}
    ${vw('padding-right', 17, 23)}
    ${vw('padding-left', 17, 23)}
    ${vw('padding-bottom', 12, 7)}
    &:focus {
      outline: none;
    }
  }
  img {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    ${vw('right', 21, 26)}
    ${vw('width', 21, 30)}
  }
`;

const Container = styled.div`
  position: relative;
`;

export default Select;
