import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const MapImage = (props) => {
  const { categories, activeCategory } = props;

  const $map = useRef();

  const browser = useSelector((state) => state.browser);

  useEffect(() => {
    if (browser.is.mobile) {
      $map.current.scrollLeft =
        $map.current.scrollWidth / 2 - window.innerWidth / 2;
    }
  }, [browser.is]);

  return (
    <Root>
      <Arrow>
        <img
          src={require('src/assets/images/global/form_dropdown_arrow_thick_yellow.svg')}
          alt="Scroll Left"
        />
      </Arrow>
      <Scroll ref={$map}>
        <div>
          <Logo>
            <img
              src={require('src/assets/images/neighbourhood/1818_location.svg')}
              alt="1818 Alberni"
            />
          </Logo>
          <img
            src={require('src/assets/images/neighbourhood/neighbourhood_map.png')}
            alt="Neighbourhood Map"
            className="map"
          />

          {categories.map((category, index) => (
            <Category
              key={index}
              style={{
                opacity: index === activeCategory ? 1 : 0,
                pointerEvents: index === activeCategory ? 'all' : 'none',
              }}
            >
              {category.points.map((point, p) => (
                <>
                  <Point key={p} top={point.top} left={point.left}>
                    <p className="map-point granite">{p + 1}</p>
                  </Point>
                  {point.line && (
                    <Line
                      length={point.line.length}
                      angle={point.line.angle}
                      top={point.line.top}
                      left={point.line.left}
                    />
                  )}
                </>
              ))}
              {index === 2 && <Dot />}
              {index === 2 && <SmallDot left="73%" top="52%" />}
              {index === 2 && <SmallDot left="74.1%" top="49.5%" />}
            </Category>
          ))}
        </div>
      </Scroll>
      <Arrow right>
        <img
          src={require('src/assets/images/global/form_dropdown_arrow_thick_yellow.svg')}
          alt="Scroll Right"
        />
      </Arrow>
    </Root>
  );
};

MapImage.propTypes = {
  categories: PropTypes.array,
  activeCategory: PropTypes.number,
};

const Root = styled.div`
  position: relative;
  overflow: hidden;
  ${vw('width', '100%', '100%', 1142)}
  ${vw('height', 420, 504, 750)}
  .map {
    height: 100%;
    width: auto;
  }
  @media ${media.tablet} {
    overflow: hidden;
    .map {
      width: 100%;
    }
  }
`;

const Scroll = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  > div {
    height: 100%;
    position: absolute;
    top: 0;
  }
`;

const Category = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: ${({ theme }) => theme.transition};
`;

const Point = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.yellow};
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  z-index: 2;
  ${vw('width', 20, 24)}
  ${vw('height', 20, 24)}
  ${vw('padding-top', 5, 6)}
   ${vw('padding-bottom', 1, 4)}
`;

const Dot = styled.div`
  position: absolute;
  top: 51.6%;
  left: 69.7%;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.yellow};
  ${vw('width', 12, 16.6)}
  ${vw('height', 12, 16.6)}
`;

const SmallDot = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.yellow};
  ${vw('width', 5, 6.7)}
  ${vw('height', 5, 6.7)}
`;

const Line = styled.div`
  width: 1.5px;
  height: ${(props) => props.length};
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  /* transform-origin: top center; */
  transform: ${(props) => `rotate(${props.angle}deg)`};
  background-color: ${({ theme }) => theme.color.yellow};
`;

const Logo = styled.div`
  position: absolute;
  z-index: 2;
  ${vw('top', 55, 65.5, 98)}
  ${vw('left', 277.5, 334, 496.5)}
  img {
    display: block;
    height: auto;
    ${vw('width', 58, 69, 103)}
  }
  &::after {
    content: '';
    position: absolute;
    top: 99%;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.color.yellow};
    z-index: 2;
    ${vw('width', 2)}
    ${vw('height', 110, 131, 195)}
  }
`;

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: ${(props) =>
    props.right ? 'translate(60%, -50%)' : 'translate(-60%, -50%)'};
  left: ${(props) => (props.right ? 'auto' : 0)};
  right: ${(props) => (props.right ? 0 : 'auto')};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.granite};
  z-index: 3;
  ${vw('width', 60)}
  ${vw('height', 60)}
  img {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
      ${(props) => (props.right ? 'rotate(-90deg)' : 'rotate(90deg)')};
    height: auto;
    ${vw('width', 14)}
    ${(props) => (props.right ? vw('left', 8) : vw('right', 8))}
  }
  @media ${media.tablet} {
    display: none;
  }
`;

export default MapImage;
