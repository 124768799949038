import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Page from 'components/Page';
import Hero from 'components/layout/Hero';
import FullImage from 'components/layout/FullImage';
import NextPage from 'components/layout/NextPage';
import CenterLine from 'components/layout/CenterLine';
import DoubleParagraph from 'components/typography/DoubleParagraph';
import TitleContent from 'components/typography/TitleContent';
import ColourScheme from './elements/ColourScheme';
import DownloadButtons from './elements/DownloadButtons';

const Residences = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Page>
          <Hero image={require('src/assets/images/residences/hero.jpg')} />
          <Wrapper>
            <CenterLine tabTitleHeight={236} deskTitleHeight={284} />
            <DoubleParagraph
              largeTitle
              title={t.residences.intro.title}
              leftText={t.residences.intro.leftText}
              rightText={t.residences.intro.rightText}
            />
          </Wrapper>
          <FullImage
            img={require('src/assets/images/residences/residences_view.jpg')}
            alt="View"
            caption={t.residences.views.caption}
            disclaimer={t.residences.views.disclaimer}
            mediumBottomMargin
          />
          <FullImage
            img={require('src/assets/images/residences/residences_bespoke-interiors.jpg')}
            alt="Bespoke Interiors"
          />
          <TitleContent
            title={t.residences.interiors.title}
            content={t.residences.interiors.text}
            largeMargin
          />
          <FullImage
            img={require('src/assets/images/residences/residences_b-o.jpg')}
            alt="Bang & Olufsen"
          />
          <DoubleParagraph
            noTopMargin
            title={t.residences.collage.content[0].title}
            leftText={t.residences.collage.content[0].text}
            rightText={{
              list: t.residences.collage.content[1].list[0].points,
            }}
            largeRightSide
            xlTitle
            noTitleSpaces
          />
          <TitleContent
            title={t.residences.goodTaste.title}
            content={t.residences.goodTaste.text}
          />
          <ColourScheme
            title={t.residences.schemes.kitchen.title}
            text={t.residences.schemes.kitchen.text}
            content={t.residences.schemes.kitchen.options}
            images={[
              require('src/assets/images/residences/kitchen_ivory.jpg'),
              require('src/assets/images/residences/kitchen_emerald.jpg'),
              require('src/assets/images/residences/kitchen_copper.jpg'),
            ]}
          />
          <ColourScheme
            title={t.residences.schemes.bath.title}
            text={t.residences.schemes.bath.text}
            content={t.residences.schemes.bath.options}
            images={[
              require('src/assets/images/residences/ensuite_ivory.jpg'),
              require('src/assets/images/residences/ensuite_emerald.jpg'),
              require('src/assets/images/residences/ensuite_copper.jpg'),
            ]}
            reverse
          />
          <DownloadButtons />
          <NextPage
            text={t.residences.nextPage}
            to={`${getLocaleURL()}/floorplans`}
            image={require('src/assets/images/residences/link_bg_floorplans.png')}
          />
        </Page>
      )}
    </Intl>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

export default Residences;
