import React from 'react';
import styled from 'styled-components';

import Facebook from 'components/logo/Facebook';
import Instagram from 'components/logo/Instagram';
import Wechat from 'components/logo/Wechat';

import { ReactComponent as Rennie } from 'src/assets/images/logo/logos_rennie.svg';
import { ReactComponent as Landa } from 'src/assets/images/logo/logos_landa.svg';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Footer = () => {
  return (
    <Root>
      <Wrapper>
        <Logo
          src={require('src/assets/images/logo/1818_logo_vertical.svg')}
          alt="1818 Alberni"
        />
        <Container>
          <Logos>
            <a
              href="https://www.landaglobal.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LandaLogo />
            </a>
            <a
              href="https://rennie.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RennieLogo />
            </a>
          </Logos>
          <Social>
            <Facebook />
            <Instagram />
            <Wechat />
          </Social>
        </Container>
      </Wrapper>
      <Disclaimer>
        <p className="footer disclaimer cash">
          This is not an offering for sale. Any such offering can only be made
          with a disclosure statement. Prices are subject to change without
          notice. The developer reserves the right to make changes and
          modifications to the information contained herein without prior
          notice. Artist’s renderings and maps are representations only and may
          not be accurate. E.&amp;O.E.
        </p>
        <p className="footer disclaimer cash">
          Website by{' '}
          <a
            href="https://www.bamdigital.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="cash"
          >
            Bam Digital
          </a>
        </p>
      </Disclaimer>
    </Root>
  );
};

const Root = styled.footer`
  width: 100%;
  /* overflow: hidden; */
  background-image: url(${require('src/assets/images/global/footer_background.jpg')});
  background-size: cover;
  background-position: center;
  width: 100%;
  // height only here to show dimensions, should probably be changed to padding
  ${vw('padding-top', 16, 40)}
  ${vw('padding-left', 12, 40)}
  ${vw('padding-right', 12, 40)}
  ${vw('padding-bottom', 12, 20, 40)}
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  ${vw('margin-top', 0, 35)}
`;

const Wrapper = styled.div`
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const Disclaimer = styled.div`
  ${vw('margin-top', 30, 40, -48)}
  ${vw('margin-left', 0, 0, 245)}
  p:first-of-type {
    ${vw('margin-bottom', 24, 0)}
  }
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    p:first-of-type {
      width: ${vwTablet(495)};
    }
  }
  @media ${media.desktop} {
    p:first-of-type {
      width: ${vwDesktop(1160)};
    }
    a {
      display: inline-block;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${({ theme }) => theme.color.yellow};
        opacity: 0;
        transition: 0.4s ease-out;
      }
      &:hover::after {
        opacity: 1;
      }
    }
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;
  svg {
    display: block;
    width: auto;
    ${vw('height', 18, 22)}
  }
  a {
    ${vw('margin-right', 24, 30)}
  }
  @media ${media.tablet} {
    order: 1;
  }
`;

const Logos = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('margin-left', 0, 60)}
  a:first-of-type {
    ${vw('margin-bottom', 24, 0)}
    ${vw('margin-right', 0, 40)}
  }
  @media ${media.tablet} {
    flex-direction: row;
    align-items: flex-end;
    order: 2;
  }
`;

const Logo = styled.img`
  display: block;
  height: auto;
  ${vw('width', 65, 80, 118)}
  ${vw('margin-bottom', 120, 0)}
`;

const RennieLogo = styled(Rennie)`
  ${vw('width', 77, 90)}
  @media ${media.desktop} {
    path {
      transition: 0.4s ease-out;
    }
    &:hover {
      path {
        fill: ${({ theme }) => theme.color.yellow};
      }
    }
  }
`;

const LandaLogo = styled(Landa)`
  ${vw('width', 87.5, 100)}
  @media ${media.desktop} {
    path {
      transition: 0.4s ease-out;
    }
    &:hover {
      path {
        fill: ${({ theme }) => theme.color.yellow};
      }
    }
  }
`;

export default Footer;
