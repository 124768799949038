import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Button from 'components/Button';
import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Content = () => {
  // for directions
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      return window.open(
        'https://www.google.com/maps/dir//1802+Alberni+St,+Vancouver,+BC+V6G+2L6/@49.2922251,-123.1358553,17.93z/data=!4m5!3m4!1s0x5486718845ebde61:0x2ebfa7c9ca85f382!8m2!3d49.2922!4d-123.1346561',
        '_blank'
      );
    }
  };

  const success = (position) => {
    window.open(
      `https://www.google.com/maps/dir/${position.coords.latitude},${position.coords.longitude}/1802+Alberni+St,+Vancouver,+BC+V6G+2L6/@49.2922251,-123.1358553,17.93z/data=!4m5!3m4!1s0x5486718845ebde61:0x2ebfa7c9ca85f382!8m2!3d49.2922!4d-123.1346561`
    );
  };

  const error = () => {
    window.open(
      'https://www.google.com/maps/dir//1802+Alberni+St,+Vancouver,+BC+V6G+2L6/@49.2922251,-123.1358553,17.93z/data=!4m5!3m4!1s0x5486718845ebde61:0x2ebfa7c9ca85f382!8m2!3d49.2922!4d-123.1346561',
      '_blank'
    );
  };

  return (
    <Intl>
      {(t) => (
        <FadeUp>
          <Root>
            <Container>
              <Map
                src={require('src/assets/images/contact/sales_gallery_map.jpg')}
                alt="1818 Sales Gallery Location"
                onClick={() => getLocation()}
              />
              <Wrapper>
                <h4 className="strapline cash uppercase">
                  {t.contact.content.gallery}
                </h4>
                <p className="cash">
                  {t.contact.content.hours}
                </p>
                {/* <StyledButton
                  href="mailto:info@1818alberni.com"
                  text={t.contact.content.button}
                  ghost
                  white
                /> */}
              </Wrapper>
            </Container>
            <StyledButton
              href="mailto:info@1818alberni.com"
              text={t.contact.content.button}
              ghost
              mobile
              white
            />
            <StyledButton
              href="mailto:info@1818alberni.com"
              text={t.contact.content.button}
              ghost
              tablet
            />
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.graniteDark};
  ${vw('padding-bottom', 60, 120, 0)}
  @media ${media.tablet} {
    background-color: transparent;
  }
  @media ${media.desktop} {
    background-color: ${({ theme }) => theme.color.graniteDark};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  ${vw('padding-left', 60, 104, 240)}
  ${vw('padding-right', 60)}
  ${vw('margin-top', 60, 0)}
  ${vw('margin-bottom', 60, 0)}
  h4 {
    ${vw('margin-bottom', 30, 40)}
  }
  > p {
    ${vw('margin-bottom', 0, 0, 80)}
  }
  span {
    cursor: pointer;
  }
`;

const Container = styled.div`
  ${vw('margin-bottom', 0, 60, 0)}
  @media ${media.tablet} {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: ${({ theme }) => theme.color.graniteDark};
  }
`;

const Map = styled.img`
  display: block;
  cursor: pointer;
  height: auto;
  ${vw('width', '100%', 384, 882)}
`;

const StyledButton = styled(Button)`
  display: ${(props) => (props.mobile ? 'block' : 'none')};
  @media ${media.tablet} {
    display: ${(props) => (props.tablet ? 'block' : 'none')};
  }
  @media ${media.desktop} {
    display: ${(props) => (props.mobile || props.tablet ? 'none' : 'block')};
  }
`;

export default Content;
