export default {
  a6: {
    id: 'a6',
    bed: 3,
    bath: 3.5,
    flex: true,
    den: true,
    interior: '2,521',
    exterior: '199',
    total: '2,720',
    level: 6,
  },
  b6: {
    id: 'b6',
    bed: 2,
    bath: 2,
    flex: false,
    den: false,
    interior: '972',
    exterior: '99',
    total: '1,071',
    level: 6,
  },
  c6: {
    id: 'c6',
    bed: 2,
    bath: 2,
    flex: true,
    den: false,
    interior: '1,116',
    exterior: '56',
    total: '1,172',
    level: 6,
  },
  a8: {
    id: 'a8',
    bed: 3,
    bath: 3.5,
    flex: true,
    den: true,
    interior: '2,521',
    exterior: '175',
    total: '2,696',
    level: 8,
  },
  b8: {
    id: 'b8',
    bed: 2,
    bath: 2,
    flex: false,
    den: false,
    interior: '972',
    exterior: '99',
    total: '1,071',
    level: 8,
  },
  c8: {
    id: 'c8',
    bed: 2,
    bath: 2,
    flex: true,
    den: false,
    interior: '1,116',
    exterior: '56',
    total: '1,172 ',
    level: 8,
  },
  a11: {
    id: 'a11',
    bed: 3,
    bath: 3.5,
    flex: true,
    den: false,
    interior: '2,301',
    exterior: '187',
    total: '2,488',
    level: 11,
  },
  b11: {
    id: 'b11',
    bed: 2,
    bath: 2,
    flex: true,
    den: true,
    interior: '1,252',
    exterior: '96',
    total: '1,348',
    level: 11,
  },
  c11: {
    id: 'c11',
    bed: 2,
    bath: 2,
    flex: true,
    den: true,
    interior: '1,249',
    exterior: '93',
    total: '1,342',
    level: 11,
  },
  a14: {
    id: 'a14',
    bed: 3,
    bath: 3.5,
    flex: true,
    den: false,
    interior: '2,195',
    exterior: '336',
    total: '2,531',
    level: 14,
  },
  b14: {
    id: 'b14',
    bed: 2,
    bath: 2,
    flex: false,
    den: false,
    interior: '1,086',
    exterior: '119',
    total: '1,205',
    level: 14,
  },
  c14: {
    id: 'c14',
    bed: 2,
    bath: 2,
    flex: true,
    den: false,
    interior: '1,116',
    exterior: '56',
    total: '1,172',
    level: 14,
  },
  a19: {
    id: 'a19',
    bed: 3,
    bath: 3.5,
    flex: true,
    den: true,
    interior: '2,521',
    exterior: '175',
    total: '2,696',
    level: 19,
  },
  b19: {
    id: 'b19',
    bed: 2,
    bath: 2,
    flex: true,
    den: true,
    interior: '1,243',
    exterior: '108',
    total: '1,351',
    level: 19,
  },
  c19: {
    id: 'c19',
    bed: 2,
    bath: 2,
    flex: true,
    den: true,
    interior: '1,249',
    exterior: '104',
    total: '1,353',
    level: 19,
  },
};
