import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import Topbar from './elements/Topbar';
import Overlay from './elements/Overlay';
import Logo from './elements/Logo';

const lightHeaders = [
  'neighbourhood',
  'architecture',
  'lifestyle',
  'residences',
];

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLightHeader, setIsLightHeader] = useState(false);

  const location = useLocation();

  useEffect(() => {
    // Light headers should be any pages in the array above, plus the home page
    if (
      lightHeaders.some((item) => location.pathname.includes(item)) ||
      location.pathname.replaceAll('/', '') === '' ||
      location.pathname.replaceAll('/', '') === 'tc'
    ) {
      setIsLightHeader(true);
      window.addEventListener('scroll', (e) => handleScroll(e));
    } else {
      setTimeout(() => setIsLightHeader(false), 0);
    }
    return () => window.removeEventListener('scroll', (e) => handleScroll(e));
  }, [location.pathname]);

  const handleScroll = (e) => {
    const hero = document.getElementById('hero');
    if (hero) {
      if (e.target.scrollingElement.scrollTop >= hero.offsetHeight) {
        setIsLightHeader(false);
      } else {
        setIsLightHeader(true);
      }
    }
  };

  const handleMenuButton = () => {
    if (isMenuOpen) {
      document.body.style.overflow = '';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    document.body.style.overflow = '';
    setIsMenuOpen(false);
  };

  const isHome =
    location.pathname.replaceAll('/', '') === '' ||
    location.pathname.replaceAll('/', '') === 'tc';

  return (
    <Root>
      <Topbar
        handleMenu={handleMenuButton}
        isMenuOpen={isMenuOpen}
        handleCloseMenu={handleCloseMenu}
        dark={!isLightHeader}
        home={isHome}
      />
      <Overlay isOpen={isMenuOpen} handleClose={handleCloseMenu} />
      <Logo dark={!isLightHeader} home={isHome} isMenuOpen={isMenuOpen} />
    </Root>
  );
}

const Root = styled.div``;

export default Header;
