export default {
  color: {
    yellow: '#FFE711',
    yellowDark: '#F1D900',
    cash: '#EDEDEC',
    cashLight: '#F5F7F5',
    cashDark: '#EBEBE7',
    granite: '#383C3F',
    graniteDark: '#202122',
    offWhite: '#F8F9F8',
  },
  font: {
    header: 'Magnat Poster',
    family: 'Campton',
  },
  transition: '0.4s ease',
};
