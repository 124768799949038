import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Container from 'components/layout/Container';
import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const ContentBlock = (props) => {
  const { content, image, reverse, vertical, to } = props;
  return (
    <Container>
      <FadeUp>
        <Root reverse={reverse} vertical={vertical}>
          <Wrapper vertical={vertical} reverse={reverse}>
            <Image src={image} vertical={vertical} alt={content.title} />
            <h1 className="granite">{content.title}</h1>
          </Wrapper>
          <Content reverse={reverse} vertical={vertical}>
            <div className="text">
              <p className="granite">{content.text}</p>
            </div>
            <div className="button-container">
              <Button text={content.button} to={to} ghost />
            </div>
          </Content>
        </Root>
      </FadeUp>
    </Container>
  );
};

ContentBlock.propTypes = {
  content: PropTypes.object,
  reverse: PropTypes.bool,
  vertical: PropTypes.bool,
  image: PropTypes.string,
  to: PropTypes.string,
};

const Root = styled.div`
  ${(props) => !props.vertical && vw('padding-left', 0, 0, 37)}
  ${(props) => !props.vertical && vw('padding-right', 0, 0, 37)}
  ${vw('margin-bottom', 60, 120, 160)}
  h1 {
    ${vw('margin-top', 15, 0)}
    ${vw('margin-bottom', 15, 0)}
    ${(props) =>
      props.vertical ? vw('width', '100%') : vw('width', '100%', 290, 580)}
  }
  @media ${media.tablet} {
    h1 {
      order: ${(props) => (props.reverse ? 1 : 2)};
      text-align: ${(props) => {
        if (props.reverse) {
          return 'right';
        } else if (props.vertical) {
          return 'center';
        } else {
          return 'left';
        }
      }};
      br {
        display: none;
      }
    }
  }
`;

const Wrapper = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
    justify-content: space-between;
    align-items: center;
    img {
      order: ${(props) => (props.reverse ? 2 : 1)};
    }
  }
  @media ${media.desktop} {
    justify-content: space-between;
    align-items: flex-end;
  }
`;

const Content = styled.div`
  ${vw('margin-top', 0, 30, 40)}
  .text {
    ${(props) =>
      props.vertical ? vw('width', 'auto') : vw('width', 'auto', 'auto', 580)}
    > p {
      ${(props) =>
        props.vertical
          ? vw('margin-bottom', 15, 30, 40)
          : vw('margin-bottom', 15, 0)}
      ${(props) =>
        props.vertical
          ? vw('width', '100%', 452, 516)
          : vw('width', '100%', 290, 340)}
    }
  }
  .button-container {
    ${(props) =>
      props.vertical
        ? vw('width', '100%', 'fit-content')
        : vw('width', '100%', 290, 580)}
  }
  @media ${media.tablet} {
    display: flex;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
    align-items: ${(props) => (props.vertical ? 'center' : 'flex-start')};
    justify-content: space-between;
    .text {
      order: ${(props) => (props.reverse ? 2 : 1)};
    }
    .text > p {
      text-align: ${(props) => {
        if (props.reverse) {
          return 'left';
        } else if (props.vertical) {
          return 'center';
        } else {
          return 'right';
        }
      }};
    }
    .button-container {
      display: flex;
      justify-content: ${(props) =>
        props.reverse ? 'flex-end' : 'flex-start'};
      order: ${(props) => (props.reverse ? 1 : 2)};
    }
  }
  @media ${media.desktop} {
    width: 100%;
    justify-content: space-between;
    .text {
      display: flex;
      justify-content: ${(props) =>
        props.reverse ? 'flex-start' : 'flex-end'};
    }
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: auto;
  ${(props) =>
    props.vertical ? vw('width', '100%') : vw('width', '100%', 290, 580)}
  ${(props) => props.vertical && vw('margin-bottom', 0, 30, 80)}
`;

export default ContentBlock;
