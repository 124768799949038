import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from './utils';
import media from './media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    background: ${theme.color.cashLight};
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    background-color:white;
    font-family: 'Roboto';
    iframe {
      display: none;
    }
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${theme.color.primary};
  }

  main {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fadeInUp {
    animation: fadeInUp 0.4s ease-out;
  }

  .grecaptcha-badge {
    transition: opacity 0.4s ease-out, right 0.3s ease 0s !important;
  }
 
  .yellow {
    color: ${theme.color.yellow};
  }

  .granite {
    color: ${theme.color.granite};
  }

  .cash {
    color: ${theme.color.cash};
  }

  .cash-light {
    color: ${theme.color.cashLight};
  }

  .uppercase {
    text-transform: uppercase;
  }

  .underline {
    text-decoration: underline;
  }

  h1 {
    font-family: ${theme.font.header};
    font-weight: 300;
    font-style: normal;
    ${vw('font-size', 30, 60)}
    ${vw('line-height', 38, 76)}
    &.normal {
      font-weight: 400;
    }
    &.small {
      ${vw('font-size', 36)}
      ${vw('line-height', 46)}
    }
  }

  h2 {
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: 300;
    ${vw('font-size', 12, 18)}
    ${vw('line-height', 22, 27)}
    ${vw('letter-spacing', 1, 2)}
    &.strapline {
      ${vw('line-height', 21)}
    }
  }

  p, li {
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: 300;
    ${vw('font-size', 14, 18)}
    ${vw('line-height', 21, 28)}
  }

  li {
    list-style-type: none;
    display: flex;
    &::before {
      content: '∙';
      font-weight: bold;
      ${vw('margin-right', 5)}
      ${vw('margin-left', 5)}
      color: ${theme.color.granite};
    }
  }

  .menu {
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: 300;
    &.main {
      ${vw('font-size', 18, 28)}
      ${vw('line-height', 21, 32)}
      ${vw('letter-spacing', 2, 3)}
    }
    &.subitem {
      ${vw('font-size', 15, 18)}
      ${vw('line-height', 18, 18)}
      ${vw('letter-spacing', 2, 4)}
    }
  }

  .button {
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: normal;
    ${vw('font-size', 14, 18)}
    ${vw('line-height', 16, 21)}
    ${vw('letter-spacing', 4, 6)}
    &.lang {
      font-weight: 400;
      ${vw('font-size', 15, 14, 18)}
      ${vw('line-height', 18, 16, 21)}
      ${vw('letter-spacing', 2, 4, 6)}
    }
  }

  .strapline {
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: 300;
    ${vw('font-size', 12, 18)}
    ${vw('line-height', 14, 21)}
    ${vw('letter-spacing', 1, 2)}
    &.normal {
      font-weight: 400;
    }
  }

  .map-point {
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: 400;
    ${vw('font-size', 12)}
    ${vw('line-height', 14)}
  }

  .team-project {
    font-family: ${theme.font.family};
    font-style: normal;
    font-weight: 400;
    ${vw('font-size', 12, 16)}
    ${vw('line-height', 14, 19)}
    ${vw('letter-spacing', 1, 2)}
  }

  .number {
    font-family: ${theme.font.header};
    font-style: normal;
    font-weight: normal;
    ${vw('font-size', 48, 80)}
    ${vw('line-height', 61, 101)}
  }

  .mobile-dropdown {
    font-family: ${theme.font.header};
    font-weight: 300;
    font-style: normal;
    ${vw('font-size', 30, 60)}
    ${vw('line-height', 38, 76)}
  }

  .floorplans {
    &.unit-name {
      font-family: ${theme.font.header};
      font-weight: 400;
      font-style: normal;
      ${vw('font-size', 50, 60)}
      ${vw('line-height', 80, 101)}
    }
    &.disclaimer {
      font-family: ${theme.font.family};
      font-style: normal;
      font-weight: 300;
      ${vw('font-size', 10)}
      ${vw('line-height', 12)}
      ${vw('letter-spacing', 0.5)}
    }
  }

  .footer {
    &.disclaimer {
      font-family: ${theme.font.family};
      font-style: normal;
      font-weight: 400;
      ${vw('font-size', 10, 12)}
      ${vw('line-height', 12, 16)}
      ${vw('letter-spacing', 0.2, 0.3)}
      a {
        font-weight: 700;
        text-decoration: underline;
        @media ${media.desktop} {
          text-decoration: none;
        }
      }
    }
  }

  .form {
    &.input {
      font-family: ${theme.font.family};
      font-style: normal;
      font-weight: 300;
      text-transform: uppercase;
      ${vw('font-size', 14, 18)}
      ${vw('line-height', 16, 21)}
      ${vw('letter-spacing', 1, 2)}
    }
    &.content {
      font-family: ${theme.font.header};
      font-style: normal;
      font-weight: 300;
      ${vw('font-size', 21, 36)}
      ${vw('line-height', 27, 46)}
      ${vw('letter-spacing', 0.3)}
    }
    &.disclaimer {
      font-family: ${theme.font.family};
      font-style: normal;
      font-weight: 300;
      letter-spacing: normal;
      ${vw('font-size', 11, 12)}
      ${vw('line-height', 14, 16)}
    }
  }
`;
