import React from 'react';

export default {
  switchLang: '中文',
  buttons: {
    homepage: 'Back to Homepage',
  },
  popup: {
    title: 'Construction has begun',
    text: "We’re excited to announce that while we are under construction our current Sales Gallery will be transitioning to a new location. In the meantime, contact our team for more information.",
    button: 'Get in touch',
  },
  header: {
    register: 'Register',
    bookAppt: 'book an appointment',
    menu: {
      items: [
        {
          text: 'Neighbourhood',
          to: '/neighbourhood',
        },
        {
          text: 'Architecture',
          to: '/architecture',
        },
        {
          text: 'Lifestyle & Services',
          to: '/lifestyle',
        },
        {
          text: 'Residences',
          to: '/residences',
        },
      ],
      subitems: [
        {
          text: 'FLOORPLANS',
          to: '/floorplans',
        },
        {
          text: 'TEAM',
          to: '/team',
        },
        {
          text: 'CONTACT',
          to: '/contact',
        },
      ],
    },
  },
  home: {
    intro: {
      number: '54',
      title: (
        <>
          intimate luxury
          <br />
          residences <br />
          by Stanley Park
        </>
      ),
      leftText:
        'Cityside and parkside. Elegant and expansive. Striking and sophisticated. 1818 Alberni is a multilayered experience in elevated luxury living. The boutique collection seamlessly weaves iconic contemporary architecture, interior finishes of the highest quality and ingenious design details for harmonious living in perfect balance.',
      rightText:
        'It’s a coveted address, nestled in an inviting downtown neighbourhood where dynamic Vancouver mingles with the wild, wondrous nature of world-renowned Stanley Park. Bask in spectacular city and ocean views, serene seawall strolls, and a lifestyle of comfort and convenience in the most livable city in the world.',
    },
    content: {
      neighbourhood: {
        title: (
          <>
            Where <br />
            Vancouver <br />
            lives
          </>
        ),
        button: 'Neighbourhood',
        text: 'Vancouver’s most animated and enriching adventures are all just steps from home.',
      },
      architecture: {
        title: (
          <>
            A <br />
            striking <br />
            silhouette
          </>
        ),
        button: 'Architecture',
        text: 'The collection is designed by acclaimed architect Foad Rafii, known worldwide for his refined contemporary aesthetic.',
      },
      lifestyle: {
        title: 'Elevated living experience',
        button: 'Lifestyle & Services',
        text: 'Landa is pleased to be partnering with Rolls-Royce Motor Cars Vancouver to provide owners of 1818 Alberni with car service, pairing convenience with exceptional comfort.',
      },
      residences: {
        title: (
          <>
            Creative <br />
            experiences <br />
            within
          </>
        ),
        button: 'Residences',
        text: 'The building’s distinct contemporary architecture and refined aesthetic are further evident throughout the interiors of every home.',
      },
    },
  },
  neighbourhood: {
    hero: {
      left: {
        title: 'Parkside',
        subtitle: 'EXCLUSIVELY',
        text: 'By the pristine beauty of sensational Stanley Park.',
      },
      right: {
        title: 'CITYSIDE',
        subtitle: 'INTIMATELY',
        text: 'Amid the urban adventures of vibrant Downtown Vancouver.',
      },
    },
    intro: {
      title: 'Lively and lush',
      leftText:
        '1818 Alberni is a coveted address nestled in an inviting downtown neighbourhood where stylish urban Vancouver mingles with the wild, wondrous nature of Stanley Park. ',
      rightText:
        'Bask in spectacular city and ocean views, serene sunset strolls, and a lifestyle of comfort and convenience in the most livable city in the world.',
    },
    collage: {
      title: (
        <>
          Where <br />
          Vancouver <br />
          lives
        </>
      ),
      content: [
        '1818 Alberni rises above lush Lost Lagoon and sprawling Stanley Park, with a thousand acres of old growth forest, blooming gardens and winding trails to wander. ',
        'Take a stroll through the park in the, soak up the sun at English Bay in the afternoon, then visit one of your favourite restaurants for a delicious dinner—a perfect full-course day. ',
        'Vancouver’s most animated and enriching adventures are all just steps from home.',
      ],
    },
    map: {
      title: (
        <>
          In the heart
          <br />
          of a thriving city
        </>
      ),
      text: 'Your home at 1818 Alberni offers unparalleled access to the very best the city has to offer. Start here and enjoy a lifestyle full of enriching experiences.',
      categories: [
        {
          title: 'Convenience',
          points: [
            {
              text: 'Whole Foods Market',
              top: '48.4%',
              left: '52.8%',
            },
            {
              text: 'Equinox',
              top: '45.6%',
              left: '67.6%',
            },
            {
              text: 'Urban Fare',
              top: '50.3%',
              left: '66.1%',
            },
          ],
        },
        {
          title: 'Entertainment',
          points: [
            {
              text: 'Vancouver Art Gallery',
              top: '48.8%',
              left: '74%',
            },
            {
              text: 'Queen Elizabeth Theatre',
              top: '45.6%',
              left: '85.3%',
            },
            {
              text: 'Parq Casino',
              top: '57.8%',
              left: '93.95%',
            },
            {
              text: 'Shangri-La & Chi the Spa',
              top: '47.6%',
              left: '70.6%',
            },
            {
              text: 'Rosewood Hotel Georgia',
              top: '45.6%',
              left: '75.7%',
            },
            {
              text: 'Fairmont Pacific Rim & Willow Stream Spa',
              top: '36.1%',
              left: '71.6%',
            },
            {
              text: 'Royal Vancouver Yacht Club',
              top: '31.5%',
              left: '49%',
            },
          ],
        },
        {
          title: 'Shopping',
          points: [
            {
              text: 'Prada',
              top: '57.3%',
              left: '69.5%',
              line: {
                length: '4.1%',
                angle: 0,
                top: '53.2%',
                left: '70.5%',
              },
            },
            {
              text: 'Hermès',
              top: '45.9%',
              left: '72.8%',
            },
            {
              text: 'De Beers Jewellers',
              top: '57.3%',
              left: '66.5%',
              line: {
                length: '6%',
                angle: 40,
                top: '52.8%',
                left: '69%',
              },
            },
            {
              text: 'Louis Vuitton',
              top: '56%',
              left: '73.3%',
              line: {
                length: '6%',
                angle: 0,
                top: '50.2%',
                left: '74.3%',
              },
            },
            {
              text: 'Saint Laurent',
              top: '57.3%',
              left: '63.7%',
              line: {
                length: '9%',
                angle: 55,
                top: '51%',
                left: '67.5%',
              },
            },
            {
              text: 'Holt Renfrew',
              top: '41.6%',
              left: '77.4%',
            },
            {
              text: 'Nordstrom',
              top: '53.9%',
              left: '76.4%',
            },
            {
              text: 'Gucci',
              top: '44.9%',
              left: '62.8%',
              line: {
                length: '11%',
                angle: 120,
                top: '45%',
                left: '67.5%',
              },
            },
            {
              text: 'Hublot',
              top: '44.9%',
              left: '65.6%',
              line: {
                length: '7%',
                angle: 137,
                top: '46%',
                left: '68.5%',
              },
            },
            {
              text: 'Vacheron Constantin',
              top: '46.9%',
              left: '70.6%',
            },
            {
              text: 'Jaeger-LeCoultre',
              top: '61.5%',
              left: '71.5%',
              line: {
                length: '9.5%',
                angle: 5,
                top: '52.3%',
                left: '72.9%',
              },
            },
            {
              text: 'Rolex',
              top: '44.9%',
              left: '60%',
              line: {
                length: '15%',
                angle: 112,
                top: '42.5%',
                left: '66%',
              },
            },
            {
              text: 'Thom Browne',
              top: '57.3%',
              left: '60.8%',
              line: {
                length: '12%',
                angle: 66,
                top: '49.5%',
                left: '66%',
              },
            },
            {
              text: 'Brunello Cucinelli',
              top: '57.3%',
              left: '57.9%',
              line: {
                length: '17.2%',
                angle: 73,
                top: '47%',
                left: '64.5%',
              },
            },
            {
              text: 'Van Cleef & Arpels',
              top: '44.9%',
              left: '68.4%',
              line: {
                length: '5%',
                angle: 170,
                top: '47.5%',
                left: '70%',
              },
            },
          ],
        },
        {
          title: 'Restaurants',
          points: [
            {
              text: 'Kingyo Izakaya',
              top: '60.4%',
              left: '47.1%',
            },
            {
              text: 'Tetsu Sushi Bar',
              top: '54.7%',
              left: '47.1%',
            },
            {
              text: 'Lift Bar & Grill',
              top: '41.7%',
              left: '50.1%',
            },
            {
              text: 'Stanley Park Grill',
              top: '34%',
              left: '35.2%',
            },
            {
              text: 'Paul Bakery',
              top: '53.9%',
              left: '64.2%',
            },
            {
              text: 'Laduree',
              top: '50.4%',
              left: '62.5%',
            },
            {
              text: 'Breka Bakery',
              top: '54%',
              left: '61.7%',
            },
            {
              text: 'Thierry',
              top: '46.4%',
              left: '70.8%',
            },
            {
              text: 'Joe Fortes Seafood & Chop House',
              top: '52.9%',
              left: '67.5%',
            },
            {
              text: 'Black & Blue',
              top: '50.5%',
              left: '70.1%',
            },
            {
              text: 'Mott 32',
              top: '46.5%',
              left: '60.2%',
            },
            {
              text: 'Hawksworth',
              top: '46.4%',
              left: '74.5%',
            },
            {
              text: 'Nightingale',
              top: '38.4%',
              left: '72.9%',
            },
            {
              text: 'Coast',
              top: '50.5%',
              left: '72.5%',
            },
          ],
        },
      ],
    },
    activity: [
      {
        title: 'Walk',
        text: 'Visit lush Stanley Park, the beach at English Bay, or the city’s tree-lined streets.',
      },
      {
        title: 'Bike',
        text: 'Cycle the seawall and explore Vancouver’s expansive network of bike lanes.',
      },
      {
        title: 'Drive',
        text: 'Head north to Whistler, south to Kitsilano and countless other destinations with ease.',
      },
    ],
    nextPage: 'Architecture',
  },
  architecture: {
    hero: {
      left: {
        title: 'Inspired',
        subtitle: 'Architecturally',
        text: 'A refined presence on the city skyline.',
      },
      right: {
        title: 'Elevated',
        subtitle: 'Elegantly',
        text: 'Contemporary style with a cohesive vision.',
      },
    },
    collage: {
      title: (
        <>
          A striking
          <br />
          silhouette
        </>
      ),
      content: [
        'The collection is designed by acclaimed architect Foad Rafii, known worldwide for his refined contemporary aesthetic.',
        '1818 Alberni is poised to be a landmark addition to Vancouver’s celebrated city skyline, serving as a gateway that seamlessly connects the scintillating city and Stanley Park.',
      ],
    },
    titleContent: {
      title: (
        <>
          An inspiring
          <br />
          impression
        </>
      ),
      text: 'Composed of a series of geometric cubes assembled over an open central volume, the building’s elegantly articulated silhouette highlights the unique character of its 54 spacious homes. Expansive windows and private patios offer a glimpse of a bright and inviting living experience inside, and every thoughtful design detail is carefully composed to craft a collection of exceptional elegance.',
    },
    nextPage: 'Lifestyle & Services',
  },
  lifestyle: {
    hero: {
      left: {
        title: 'Thoughtful',
        subtitle: 'Truly',
        text: 'A stunning first impression paired with exquisite amenities.',
      },
      right: {
        title: 'Uncompromising',
        subtitle: 'Utterly',
        text: 'Complimentary services that cater to comfort.',
      },
    },
    intro: {
      title: (
        <>
          Uncompromising services
          <br />
          and thoughtful amenities
        </>
      ),
      leftText:
        'Carefully detailed amenities offer added spaces for all the essential elements of a healthy lifestyle, and you’ll find ample room to explore every aspect of self-care and personal wellbeing.',
      rightText:
        'Enjoy an invigorating workout in the fitness facility, or relax with a book in the library social lounge. On-demand services offer a rarely enjoyed degree of convenience and comfort to complement your lifestyle.',
    },
    rollsRoyce: {
      title: (
        <>
          Rolls-Royce
          <br />
          Motor Cars Vancouver:
          <br />A perfect partnership
        </>
      ),
      text: 'Rolls-Royce is synonymous around the world with the highest standards of design, craftsmanship and precision, not to mention legendary bespoke customer service. Since its founding over a century ago it has established a legacy of excellence, bringing inspired innovation across an impressive and evolving product line.',
      content: {
        title: (
          <>
            Arrive with <br />
            elegance
          </>
        ),
        text: 'Landa is pleased to be partnering with Rolls-Royce Motor Cars Vancouver to provide owners of 1818 Alberni with car service, pairing convenience with exceptional comfort. Our partnership makes available a vehicle and chauffeur to provide serene, sumptuous travel to local destinations at your leisure.',
      },
    },
    partnerStyle: {
      title: "A Partner in Style",
      text: (<>
        A partnership with Holt Renfrew brings a rare and exclusive collection of complimentary services and exceptional experiences to owners, including valet parking, personal shopping, tailoring and alterations, beauty and grooming, culinary delights, and more.
        <br/>
        <br/>
        Explore your distinctive style and enjoy tailored personal services from Canada's fashion and lifestyle retailer.
      </>)
    },
    collage1: {
      title: (
        <>
          Thoughtful, integrated
          <br />
          technology
        </>
      ),
      content: [
        {
          title: (
            <>
              Building
              <br />
              intelligence <br />
              by 1VALET
            </>
          ),
          text: '1818 Alberni includes innovative and seamlessly integrated 1VALET smart building technology to enhance your convenience and comfort. It starts with secure keyless building access that enables owners to enter using facial recognition at the main entrance—key and phone not required.',
        },
        {
          list: [
            {
              title: 'Smart Entry',
              points: [
                'Secure keyless building access using mobile phone-based facial recognition',
                'Convenient pre-arranged temporary guest access for parking, main entrance and elevator ',
                'Enhanced security with video intercom capability',
                'License plate smart reader for automated parking access ',
                'Never miss a package with instant delivery notification on your smart device',
              ],
            },
            {
              title: 'Smart Community',
              points: [
                'Book amenities instantly and independently using the 1VALET app',
                'Receive notifications related building operations and community updates',
              ],
            },
          ],
        },
      ],
    },
    collage2: {
      content: [
        {
          title: (
            <>
              Latch smart
              <br />
              access system
            </>
          ),
          text: 'The keyless entry experience continues as you ascend the elevator to your floor and all the way inside your residence. 1818 Alberni employs the Latch smart access system to enable secure entry through your front door without the need for a key. ',
        },
        {
          list: [
            {
              points: [
                'Use your phone, Apple Watch, or personal doorcode to enter your residence with ease',
                'Provide guests with temporary access, eliminating the need to share keys or create extra copies',
                'Enjoy Latch’s intuitive, thoughtfully designed system to enable a completely keyless experience',
              ],
            },
          ],
        },
      ],
    },
    concierge: {
      title: (
        <>
          Convenient concierge
          <br />
          at your service
        </>
      ),
      left: 'We all have moments in our lives where we need a bit of assistance with important tasks. Homeowners at 1818 Alberni have access to personalized concierge services to ensure any support you require is instantly accessible. Contact the concierge to easily book from an array of exclusive services that make everyday life easier and elevate special occasions to another level. The suite of services includes:',
      right: {
        list: [
          'Rolls-Royce car service with chauffeur',
          'Premier services in partnership with Holt Renfrew',
          'Summer yacht rental',
          'Laundry, dry cleaning and pressing',
          'Private chef',
          'Pet grooming, walking and sitting',
          'Complimentary move-in',
          'Car wash',
          'Interior design consultation',
        ],
      },
    },
    higherLiving: {
      title: 'Higher living',
      text: 'The first thing you notice at 1818 Alberni is the remarkable sense of light. Rich materials and a generous sense of space create a rare vibrance that permeate every surface. The grand entrance offers an inspired introduction. Voluminous ceilings are paired with tasteful contemporary artwork and distinctive detailing of the highest quality to create a first impression visitors won’t forget.',
    },
    fitness: {
      content: [
        {
          title: 'Prime fitness',
          text: 'Owners enjoy exclusive access to a fitness facility featuring state-of-the-art equipment by Technogym—including weight-training, cardio, spin bikes and yoga space—that enables you to personalize your workout and track your progress.',
        },
        {
          text: 'Elevate your heart rate or enjoy a relaxing and restorative stretching session in a bright, comfortable and well-appointed space without ever setting foot outside.',
        },
      ],
      text: (
        <>
          Owners enjoy exclusive access to a fitness facility featuring
          state-of-the-art equipment by Technogym—including weight-training,
          cardio, spin bikes and yoga space—that enables you to personalize your
          workout and track your progress.
          <br />
          <br />
          Elevate your heart rate or enjoy a relaxing and restorative stretching
          session in a bright, comfortable and well-appointed space without ever
          setting foot outside.
        </>
      ),
      title: 'Prime fitness',
    },
    nextPage: 'Residences',
  },
  residences: {
    hero: {
      left: {
        title: 'beauty',
        subtitle: 'Bespoke',
        text: 'Meticulously detailed residences with space to spare.',
      },
      right: {
        title: 'Intelligence',
        subtitle: 'Intuitive',
        text: 'Smart features that make every day exceptional.',
      },
    },
    intro: {
      title: 'Eye-opening views',
      leftText:
        'The views from 1818 Alberni are simply magical—in every season and any time of year. Select residences offer views in three directions, providing a rare perspective on the spectacular cityside and parkside setting.',
      rightText:
        'To the north lies Stanley Park, with the majestic North Shore Mountains framing the vivid panorama. To the southwest is English Bay, where the sunset creates a spectacular crescendo of colours over the ocean. ',
    },
    views: {
      caption: 'Northeast views from Level 21',
      disclaimer:
        'View photos are representations only and may not be accurate. E.&O.E.',
    },
    interiors: {
      title: 'Bespoke interiors',
      text: 'The building’s distinct contemporary architecture and refined aesthetic are further evident throughout the interiors of every home. Premium materials and finishes of the highest quality adorn every space, while carefully planned layouts create exceptionally expansive living environments ideal for any occasion.',
    },
    collage: {
      content: [
        {
          title: (
            <>
              Smarthome
              <br /> solutions
              <br /> by B&amp;O
            </>
          ),
          text: 'With Bang & Olufsen, your ideal environment is created with just the touch of a button. A convenient in-wall touchscreen offers unified control of your environment, allowing you to create an ambiance tailored to your preferences.',
        },
        {
          list: [
            {
              points: [
                'An in-wall touchscreen provides a central hub to monitor and engage your B&O Smart Home systems',
                'Automated wireless lighting and dimming control in main area, with pre-defined lighting scenes: Welcome (all on), Goodbye (all off) and Romantic (dimmed)',
                'Adjust motorized blinds in living room and dining room to control the amount of light at any time of day or night',
              ],
            },
          ],
        },
      ],
    },
    everydayLiving: {
      title: 'Exceptional Everyday Living',
      list: [
        [
          'Custom Italian closet organization systems offer ample space for your wardrobe',
          'Beautifully engineered herringbone hardwood flooring throughout*',
          'Discreet and spacious laundry alcove',
        ],
        [
          'Automated roller shades in living room for optimal convenience and comfort',
          'One of a kind, handcrafted Italian kitchen cabinetry',
          'Integrated LED lighting',
        ],
        [
          'Matching marble stone kitchen island with wood-veneer cabinets and breakfast bar*',
          'Swiss-manufactured KWC kitchen faucet and Franke undermount sink',
          'Additional drinking faucet with water filtration system',
        ],
      ],
      disclaimer: '* 3 bedroom residences only',
    },
    goodTaste: {
      title: (
        <>
          Good taste
          <br />
          is always in style
        </>
      ),
      text: (
        <>
          The hand-crafted Italian kitchens are where the magic happens.
          Three-bedroom homes feature natural stone upper cabinets in marble or
          quartzite, along with complementary natural stone counters and
          backsplash.
          <br />
          <br />A best-in-class appliance package by Wolf and Sub-Zero
          reinforces the refined ambiance.
        </>
      ),
    },
    schemes: {
      colorScheme: 'COLOUR SCHEME',
      colorSchemes: 'COLOUR SCHEMES',
      kitchen: {
        title: (
          <>
            Live in
            <br />
            full colour
          </>
        ),
        text: 'Select from three distinct colour and texture palettes, each featuring complementary contemporary hues. The generous sense of space and style of the interior design is enhanced by high ceilings and broad windows that invite natural light inside and frame picture-perfect views.',
        options: ['IVORY', 'EMERALD', 'COPPER'],
      },
      bath: {
        title: (
          <>
            Soothing
            <br />
            spaces
          </>
        ),
        text: 'The primary ensuites are designed to create a relaxed spa-like atmosphere within the comforts of home, where premium Italian floating vanities and natural stone counters enhance the elevated interiors. In three-bedroom residences, a luxurious steam shower and freestanding stone tub provide space for serious self-care. In-floor heating delivers cozy comfort no matter the season.',
        options: ['IVORY', 'EMERALD', 'COPPER'],
      },
    },
    download: {
      download: 'Download',
      button1: '2 Bed SPECIFICATIONS',
      button2: '3 Bed SPECIFICATIONS',
    },
    nextPage: 'Floorplans',
  },
  team: {
    intro: {
      title: (
        <>
          Enhance
          <br />
          Live
          <br />
          Prosper
        </>
      ),
      leftText:
        'Landa homes are distinguished by their capacity to reflect the character of their communities. Every project, no matter the scope or scale, is distinct, carefully crafted to enrich its neighbourhood through inspiring architecture and sensitive design.',
      rightText:
        'As evident with 1818 Alberni, we work with exceptional, highly acclaimed architects and designers to create homes that are sophisticated and significant, comfortable and creative, culminating in residences that feel like home from the moment you move in.',
    },
    grid: {
      captions: [
        '1400 ALBERNI',
        'OVAL',
        '1650 ALBERNI',
        'RAPHAEL',
        'CASCADE CITY',
        'CHATEAU LAURIER',
      ],
    },
    content: {
      rafii:
        'Known as one of the architects who has shaped Vancouver, Foad Rafii has an exceptional portfolio of significant residential and mixed-use projects that celebrate refined contemporary architecture in urban settings across North America.',
      rennie:
        'For over 40 years, rennie has worked with Western Canada’s top developers to deliver exceptional homes in countless communities. They bring expert knowledge, trusted experience and a uniquely human approach to real estate.',
    },
  },
  floorplans: {
    intro: {
      title: (
        <>
          Exceptionally
          <br />
          expansive living
          <br />
          environments
        </>
      ),
      text: (
        <>
          The residences are carefully planned for maximum efficiency and ease
          of everyday living. Open layouts emphasize space and flow, while
          expansive windows invite natural light throughout, amplifying the
          vibrant atmosphere.
          <br />
          <br />
          View our featured floorplans from floors 11, 14, and 19. For more
          floorplans, please contact our sales team at{' '}
          <a href="mailto:info@1818Alberni.com" className="granite underline">
            info@1818Alberni.com
          </a>
        </>
      ),
    },
    levels: {
      title: 'Select Level',
      level: 'Level',
    },
    plans: {
      bed: 'Bedroom',
      bath: 'Bathroom',
      flex: 'Flex',
      den: 'Den',
      interior: 'Interior',
      exterior: 'Exterior',
      total: 'Total',
      sf: 'S.F.',
      view: 'View Plan',
      download: 'Download Plan',
      seeAll: 'SEE all Plans',
    },
    disclaimer:
      'This is not an offering for sale. Any such offering can only be made with a disclosure statement. The developer reserves the right to make changes and modifications to the information contained herein without prior notice. Dimensions, sizes, areas, specifications layout and materials are approximate only and subject to change without notice. E.&O.E.',
  },
  contact: {
    intro: {
      title: (
        <>
          We can’t wait to
          <br /> welcome you home.
        </>
      ),
      sales: 'Sales & General Inquiries',
      follow: 'Follow us',
    },
    content: {
      gallery: 'Sales Gallery',
      hours: <>
        Sales Gallery is Now Closed to Make Way for Construction
        <br/>
        <br/>
        New Sales Gallery Coming Soon
      </>,
      button: 'book an appointment',
    },
  },
  register: {
    title: (
      <>
        Please register
        <br /> your interest
        <br /> today
      </>
    ),
    subtitle: 'Now Selling',
    firstName: 'First Name*',
    lastName: 'Last Name*',
    email: 'Email Address*',
    phone: 'Phone Number*',
    postal: 'Postal Code*',
    bedType: {
      label: 'Bedroom Type Interested',
      options: [
        {
          value: '2 Bed',
          label: '2 Bedroom',
        },
        {
          value: '3 Bed',
          label: '3 Bedroom',
        },
      ],
    },
    realtor: {
      label: 'Are you a Realtor?*',
      options: [
        {
          value: 'Yes',
          label: 'Yes',
        },
        {
          value: 'No',
          label: 'No',
        },
      ],
    },
    howHeard: {
      label: 'How did you hear about us?*',
      options: [
        {
          value: 'Realtor',
          label: 'Realtor',
        },
        {
          value: 'Signage/Walk by/Drive by',
          label: 'Signage/Walk by/Drive by',
        },
        {
          value: 'Word of Mouth',
          label: 'Word of Mouth',
        },
        {
          value: 'Direct Mailer',
          label: 'Direct Mailer',
        },
        {
          value: 'Print Ad/Mail',
          label: 'Print Ad/Mail',
        },
        {
          value: 'Radio',
          label: 'Radio',
        },
        {
          value: 'Online Advertisement',
          label: 'Online Advertisement',
        },
      ],
    },
    submit: 'Submit',
  },
  thankyou: {
    title: 'Thank you',
    text: 'A sincere thank you for your interest in 1818 Alberni. We’ll keep you apprised of any updates related to this exceptional collection of homes',
  },
  notFound: {
    title: 'Sorry!  We apologize',
    text: 'Looks like something went wrong here. The page you are looking for does not exist.',
  },
};
