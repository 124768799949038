import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const MenuButton = (props) => {
  const { dark, isOpen, handleClick } = props;
  return (
    <Root isOpen={isOpen} onClick={handleClick}>
      <Open
        width="36"
        height="24"
        viewBox="0 0 36 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        isOpen={isOpen}
      >
        <g opacity="0.9">
          <rect
            y="1"
            width="36"
            height="2"
            rx="1"
            fill={dark ? theme.color.granite : theme.color.cashLight}
          />
          <rect
            y="11"
            width="36"
            height="2"
            rx="1"
            fill={dark ? theme.color.granite : theme.color.cashLight}
          />
          <rect
            y="21"
            width="36"
            height="2"
            rx="1"
            fill={dark ? theme.color.granite : theme.color.cashLight}
          />
        </g>
      </Open>
      <Close
        width="36"
        height="28"
        viewBox="0 0 36 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        isOpen={isOpen}
      >
        <g opacity="0.9">
          <rect
            x="4.56543"
            y="26.021"
            width="36"
            height="2"
            rx="1"
            transform="rotate(-45 4.56543 26.021)"
            fill="#F5F7F5"
          />
          <rect
            x="5.97949"
            y="0.564941"
            width="36"
            height="2"
            rx="1"
            transform="rotate(45 5.97949 0.564941)"
            fill="#F5F7F5"
          />
        </g>
      </Close>
    </Root>
  );
};

MenuButton.propTypes = {
  dark: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleClick: PropTypes.func,
};

const Root = styled.button`
  position: relative;
  cursor: pointer;
  ${vw('width', 28, 28, 36)}
  ${vw('margin-left', 12, 24, 46)}
  svg {
    width: 100%;
    height: auto;
    transition: ${({ theme }) => theme.transition};
  }
  rect {
    transition: ${({ theme }) => theme.transition};
  }
`;

const Open = styled.svg`
  opacity: ${(props) => (props.isOpen ? 0 : 1)};
`;

const Close = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`;

export default MenuButton;
