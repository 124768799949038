import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'components/Intl';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const LevelSelect = (props) => {
  const { levels, activeLevel, handleLevel } = props;
  return (
    <Intl>
      {(t) => (
        <Root>
          <h4 className="strapline granite uppercase">
            {t.floorplans.levels.title}
          </h4>
          <Wrapper>
            {levels.map((item, index) => (
              <Container
                key={index}
                middle={index === 1}
                first={index === 0}
                last={index === 3 || index === 2}
              >
                <Button
                  active={activeLevel === index}
                  onClick={() => handleLevel(index)}
                >
                  <p className="button uppercase">
                    <span>{t.floorplans.levels.level}</span> {item.level}{' '}
                    {t.floorplans.levels.levelLast && (
                      <span>{t.floorplans.levels.levelLast}</span>
                    )}
                  </p>
                </Button>
              </Container>
            ))}
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

LevelSelect.propTypes = {
  levels: PropTypes.array,
  activeLevel: PropTypes.number,
  handleLevel: PropTypes.func,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('margin-bottom', 60, 60, 120)}
  ${vw('padding-top', 0, 0, 40)}
  h4 {
    ${vw('margin-bottom', 15, 0)}
  }
  @media ${media.tablet} {
    h4 {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  ${vw('margin-left', 10, 15, 60)}
  &:first-of-type {
    margin-left: 0;
  }
`;

const Button = styled.button`
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};
  display: flex;
  justify-content: center;
  background-color: ${(props) =>
    props.active ? props.theme.color.granite : 'transparent'};
  ${vw('padding-top', 12, 6)}
  ${vw('padding-bottom', 8, 6)}
  ${vw('padding-left', 21, 12)}
  ${vw('padding-right', 20, 12)}
  span {
    display: none;
  }
  p {
    color: ${(props) =>
      props.active ? props.theme.color.cash : props.theme.color.granite};
    transition: ${({ theme }) => theme.transition};
  }
  @media ${media.tablet} {
    span {
      display: inline;
    }
  }
  @media ${media.desktop} {
    &:hover {
      background-color: ${({ theme }) => theme.color.granite};
      p {
        color: ${({ theme }) => theme.color.cash};
      }
    }
  }
`;

export default LevelSelect;
