export default [
  {
    level: '11',
    plans: ['a11', 'b11', 'c11'],
  },
  {
    level: '14',
    plans: ['a14', 'b14', 'c14'],
  },
  {
    level: '19',
    plans: ['a19', 'b19', 'c19'],
  },
];
