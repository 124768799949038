import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Checkbox = () => {
  return (
    <Root>
      <input type="checkbox" name="consent" id="consent" required />
      <span />
      <label htmlFor="consent" className="form disclaimer granite">
        By clicking on the box, I am permitting Landa and rennie to contact me
        with promotional messages such as newsletters, announcements, press
        releases and event invitations regarding their products and services. I
        understand this may take the form of communication via email, telephone
        or post and consent to the collection, use and disclosure of the
        information submitted for the purposes disclosed on this page. If I want
        to receive email communication, I must give explicit consent via the
        tick box. I may withdraw my consent at any time.
      </label>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  ${vw('margin-bottom', 50, 50, 35)}
  ${vw('padding-left', 50)}
  input,
  span {
    position: absolute;
    top: 0;
    left: 0;
    ${vw('width', 24)}
    ${vw('height', 24)}
  }
  span {
    pointer-events: none;
    border: 1px solid ${({ theme }) => theme.color.granite};
    &::after {
      content: '';
      background-color: ${({ theme }) => theme.color.granite};
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${vw('width', 12)}
      ${vw('height', 12)}
    }
  }
  input {
    opacity: 0;
  }
  input:checked + span {
    background-color: ${({ theme }) => theme.color.cashDark};
  }
  input:checked + span::after {
    opacity: 1;
  }

  @media ${media.desktop} {
    input,
    span {
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }
`;

export default Checkbox;
