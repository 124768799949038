import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import Intl from 'components/Intl';
import Page from 'components/Page';
import TitleContent from 'components/typography/TitleContent';
import LevelSelect from './sections/LevelSelect';
import PlanCard from './elements/PlanCard';

import vw from 'src/styles/utils';

import data from 'src/data/plans';
import levels from 'src/data/levels';

const Floorplans = () => {
  const [activeLevel, setActiveLevel] = useState(0);

  const location = useLocation();

  const $container = useRef();

  useEffect(() => {
    if (location.state && location.state.activeLevel) {
      setActiveLevel(location.state.activeLevel);
      window.scrollTo({ top: $container.current.offsetTop - 80 });
    }
  }, [location.state]);

  const handleLevel = (i) => {
    setActiveLevel(i);
  };

  return (
    <Intl>
      {(t) => (
        <Page>
          <Space />
          <TitleContent
            title={t.floorplans.intro.title}
            content={t.floorplans.intro.text}
          />
          <div ref={$container}>
            <LevelSelect
              levels={levels}
              activeLevel={activeLevel}
              handleLevel={handleLevel}
            />
            <Container>
              {levels.map((item, index) => (
                <Wrapper key={index} active={index === activeLevel}>
                  {item.plans.map((plan, p) => (
                    <PlanCard
                      key={p}
                      plan={data[plan]}
                      activeLevel={activeLevel}
                    />
                  ))}
                </Wrapper>
              ))}
            </Container>
          </div>
        </Page>
      )}
    </Intl>
  );
};

const Space = styled.div`
  ${vw('padding-top', 120, 160, 300)}
`;

const Container = styled.div`
  position: relative;
`;

const Wrapper = styled.div`
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? 'all' : 'none')};
  position: ${(props) => (props.active ? 'relative' : 'absolute')};
  top: 0;
  left: 0;
  width: 100%;
  transition: ${({ theme }) => theme.transition};
  transition-delay: ${(props) => (props.active ? '0.4s' : '0s')};
`;

export default Floorplans;
