import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';

const Facebook = ({ dark }) => {
  return (
    <a
      href="https://www.facebook.com/LandaGlobalProperties/"
      target="_blank"
      rel="noopener noreferrer"
    >
      {' '}
      <SVG
        width="12"
        height="22"
        viewBox="0 0 12 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_779_895)">
          <path
            d="M11.6931 0.158654V3.64904H9.61737C7.99117 3.64904 7.68709 4.42909 7.68709 5.55288V8.05168H11.5609L11.0453 11.9651H7.68709V22H3.64141V11.9651H0.27002V8.05168H3.64141V5.16947C3.64141 1.82452 5.69069 0 8.67867 0C10.1066 0 11.3361 0.105769 11.6931 0.158654Z"
            fill={dark ? '#383C3F' : '#F5F7F5'}
          />
        </g>
        <defs>
          <clipPath id="clip0_779_895">
            <rect width="12" height="22" fill="white" />
          </clipPath>
        </defs>
      </SVG>
    </a>
  );
};

Facebook.propTypes = {
  dark: PropTypes.bool,
};

const SVG = styled.svg`
  @media ${media.desktop} {
    cursor: pointer;
    path,
    rect {
      transition: 0.4s ease-out;
    }
    &:hover path,
    &:hover rect {
      fill: ${({ theme }) => theme.color.yellow};
    }
  }
`;

export default Facebook;
