import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FadeUp from 'components/animation/FadeUp';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const DoubleParagraph = (props) => {
  const {
    number,
    title,
    leftText,
    leftTitle,
    rightText,
    logo,
    logoAlt,
    lessPadding,
    largeTitle,
    noTopMargin,
    largeRightSide,
    noTitleSpaces,
    xlTitle,
  } = props;
  return (
    <FadeUp>
      <Root
        largeTitle={largeTitle}
        xlTitle={xlTitle}
        noTopMargin={noTopMargin}
        noTitleSpaces={noTitleSpaces}
      >
        {number && <h1 className="number granite">{number}</h1>}
        <h1 className="granite">{title}</h1>
        {logo && <Logo src={logo} alt={logoAlt} />}
        <Wrapper lessPadding={lessPadding}>
          <Content>
            {leftTitle && <h1 className="granite text-title">{leftTitle}</h1>}
            <p className="granite">{leftText}</p>
          </Content>
          <Content largeRightSide={largeRightSide}>
            {rightText.list ? (
              <ul>
                {rightText.list.map((point, p) => (
                  <li className="granite" key={p}>
                    {point}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="granite">{rightText}</p>
            )}
          </Content>
        </Wrapper>
      </Root>
    </FadeUp>
  );
};

DoubleParagraph.propTypes = {
  number: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  leftText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  leftTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  rightText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  lessPadding: PropTypes.bool,
  largeTitle: PropTypes.bool,
  xlTitle: PropTypes.bool,
  noTopMargin: PropTypes.bool,
  largeRightSide: PropTypes.bool,
  noTitleSpaces: PropTypes.bool,
};

const Root = styled.div`
  ${(props) => !props.noTopMargin && vw('padding-top', 60, 160, 248)}
  ${vw('padding-left', 20, 0)}
  ${vw('padding-right', 20, 0)}
  @media ${media.tablet} {
    h1 {
      text-align: center;
      width: ${(props) => (props.largeTitle ? vwTablet(686) : vwTablet(481))};
      margin: 0 auto;
      &.text-title {
        text-align: right;
      }
    }
  }
  @media ${media.desktop} {
    h1 {
      width: ${(props) => {
        if (props.xlTitle) {
          return vwDesktop(800);
        } else if (props.largeTitle) {
          return vwDesktop(686);
        } else {
          return vwDesktop(481);
        }
      }};
    }
    br {
      display: ${(props) => (props.noTitleSpaces ? 'none' : 'block')};
    }
  }
`;

const Wrapper = styled.div`
  ${(props) =>
    props.lessPadding
      ? vw('margin-bottom', 30, 60, 80)
      : vw('margin-bottom', 60, 120, 160)}
  ${vw('margin-top', 0, 60, 80)}
  ${vw('padding-left', 0, 50, 478)}
  ${vw('padding-right', 0, 50, 478)}
  p, ul {
    ${vw('margin-top', 30, 0)}
  }
  li {
    ${vw('margin-bottom', 5, 10, 15)}
  }
  h1 {
    ${vw('margin-bottom', 20, 40, 80)}
  }
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
  @media ${media.desktop} {
  }
`;

const Content = styled.div`
  @media ${media.tablet} {
    width: ${vwTablet(290)};
    &:first-of-type {
      text-align: right;
    }
    p,
    ul,
    h1 {
      width: ${vwTablet(290)};
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(340)};
    p,
    ul,
    h1 {
      width: ${(props) =>
        props.largeRightSide ? vwDesktop(460) : vwDesktop(340)};
    }
  }
`;

const Logo = styled.img`
  display: block;
  width: auto;
  ${vw('height', 42, 51)}
  ${vw('margin-top', 30, 60, 80)}
  @media ${media.tablet} {
    margin-left: auto;
    margin-right: auto;
  }
`;

export default DoubleParagraph;
