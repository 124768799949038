import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'components/Intl';
import Button from 'components/Button';
import Page from 'components/Page';

import vw from 'src/styles/utils';

const SimplePage = (props) => {
  const { title, text, smallText } = props;
  return (
    <Intl>
      {(t) => (
        <Page>
          <Root smallText={smallText}>
            <h1 className="granite">{title}</h1>
            <h2 className="granite uppercase">{text}</h2>
            <Button to="/" text={t.buttons.homepage} ghost />
          </Root>
        </Page>
      )}
    </Intl>
  );
};

SimplePage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  smallText: PropTypes.bool,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  ${vw('padding-top', 238, 213)}
  ${vw('padding-bottom', 130, 160)}
  h2 {
    ${vw('margin-top', 18, 60, 80)}
    ${vw('margin-bottom', 53, 60, 80)}
    ${(props) =>
      props.smallText ? vw('width', 260, 420, 398) : vw('width', 280, 452, 516)}
  }
`;

export default SimplePage;
