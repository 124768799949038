import React from 'react';

import Intl from 'components/Intl';
import SimplePage from 'components/layout/SimplePage';

const NotFound = () => {
  return (
    <Intl>
      {(t) => (
        <SimplePage title={t.notFound.title} text={t.notFound.text} smallText />
      )}
    </Intl>
  );
};

export default NotFound;
