import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FullImage from './FullImage';
import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const FullImageContent = (props) => {
  const { title, text, image, noTopMargin, textTitle, reverse } = props;
  return (
    <FadeUp>
      <Root noTopMargin={noTopMargin}>
        <Wrapper>
          <h1 className="granite main">{title}</h1>
        </Wrapper>
        <Content reverse={reverse}>
          {image && <FullImage reverse img={image} alt={title} /> }
          <Wrapper>
            {textTitle && <h1 className="granite text-title">{textTitle}</h1>}
            <p className="granite">{text}</p>
          </Wrapper>
        </Content>
      </Root>
    </FadeUp>
  );
};

FullImageContent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  text: PropTypes.string,
  image: PropTypes.string,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  ${(props) => !props.noTopMargin && vw('padding-top', 60, 160, 248)}
  ${vw('margin-bottom', 60, 120, 160)}
  h1 {
    ${vw('margin-bottom', 20, 40, 40)}
    &.main {
      ${vw('margin-bottom', 30, 60, 80)}
    }
  }
  p {
    ${vw('width', '100%', 450, 516)}
  }
  @media ${media.tablet} {
    align-items: center;
    text-align: center;
    .text-title br {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('padding-left', 18, 50, 200)}
  ${vw('padding-right', 18, 50, 200)}
  @media ${media.tablet} {
    align-items: center;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: ${(props) => props.reverse ? 'column-reverse' : 'column'};

`

export default FullImageContent;
