import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'components/Intl';
import Button from 'components/Button';
import FadeUp from 'components/animation/FadeUp';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const PlanCard = (props) => {
  const { plan, activeLevel } = props;
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <FadeUp>
          <Root>
            <Unit>
              <h1 className="cash normal uppercase">{plan.id}</h1>
            </Unit>
            <Wrapper>
              <Image>
                <img
                  src={require(`src/assets/images/floorplans/plans/${plan.id}@2x.png`)}
                  alt={`Unit ${plan.id}`}
                />
              </Image>
              <Content>
                <Details margin>
                  <p className="strapline uppercase granite normal">
                    {plan.bed} {t.floorplans.plans.bed}{' '}
                    {plan.den && `+ ${t.floorplans.plans.den}`}{' '}
                    {plan.flex && `+ ${t.floorplans.plans.flex}`}
                  </p>
                  <p className="strapline uppercase granite normal">
                    {plan.bath} {t.floorplans.plans.bath}
                  </p>
                </Details>
                <Details>
                  <p className="strapline uppercase granite small-margin">
                    {t.floorplans.plans.interior} {plan.interior}{' '}
                    {t.floorplans.plans.sf}
                  </p>
                  <p className="strapline uppercase granite">
                    {t.floorplans.plans.exterior} {plan.exterior}{' '}
                    {t.floorplans.plans.sf}
                  </p>
                  <p className="strapline uppercase granite normal">
                    {t.floorplans.plans.total} {plan.total}{' '}
                    {t.floorplans.plans.sf}
                  </p>
                </Details>
              </Content>
            </Wrapper>
            <Container>
              <Button
                text={t.floorplans.plans.view}
                to={{
                  pathname: `${getLocaleURL()}/floorplans/${plan.id}`,
                  state: { activeLevel: activeLevel },
                }}
              />
            </Container>
          </Root>
        </FadeUp>
      )}
    </Intl>
  );
};

PlanCard.propTypes = {
  plan: PropTypes.object,
  activeLevel: PropTypes.number,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-left', 12, 40, 338)}
  ${vw('padding-right', 12, 40, 338)}
  ${vw('margin-bottom', 60, 60, 120)}
  &:last-of-type {
    ${vw('margin-bottom', 60, 120, 160)}
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.cash};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${vw('padding-top', 64, 90, 60)}
  ${vw('padding-bottom', 48, 90, 60)}
  ${vw('margin-top', -45, -80)}
  @media ${media.tablet} {
    flex-direction: row;
  }
`;

const Container = styled.div`
  ${vw('margin-top', -19, -25)}
`;

const Content = styled.div`
  ${vw('padding-left', 0, 40, 167)}
`;

const Unit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.granite};
  z-index: 2;
  ${vw('width', 90, 160)}
  ${vw('height', 90, 160)}
`;

const Details = styled.div`
  text-align: center;
  ${(props) => props.margin && vw('margin-bottom', 15, 40)}
  .strapline {
    ${vw('margin-bottom', 8, 15)}
    &:last-of-type {
      margin-bottom: 0;
    }
    &.small-margin {
      ${vw('margin-bottom', 8, 9)}
    }
  }
  @media ${media.tablet} {
    text-align: left;
  }
`;

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('width', 150, '50%')}
  ${vw('height', 150, 280, 340)}
  ${vw('margin-bottom', 15, 0)}
  ${vw('padding-left', 0, 40, 112)}
  ${vw('padding-right', 0, 40, 112)}
  img {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    display: block;
  }
`;

export default PlanCard;
